import Flex from '@lyra/ui/components/Flex'
import Modal from '@lyra/ui/components/Modal'
import ModalSection from '@lyra/ui/components/Modal/ModalSection'
import Text from '@lyra/ui/components/Text'
import React from 'react'

type Props = {
  network: string
  isOpen: boolean
  onClose: () => void
}

const SwitchNetworkModal = ({ isOpen, onClose, network }: Props) => {
  return (
    <Modal title="Switch Network" isOpen={isOpen} onClose={onClose}>
      <ModalSection>
        <Flex mb={2} alignItems="center" justifyContent={'center'}>
        <Text variant="bodyMedium"> please update the network in your wallet to {network} </Text>
        </Flex>
      </ModalSection>
    </Modal>
  )
}

export default SwitchNetworkModal