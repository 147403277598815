import Button, { ButtonSize } from '@lyra/ui/components/Button'
import ButtonShimmer from '@lyra/ui/components/Shimmer/ButtonShimmer'
import React, { useState } from 'react'
import { LayoutProps, MarginProps } from 'styled-system'

import { StarknetNetwork } from '@/app/constants/networks'
import useStarknetWallet from '@/app/hooks/account/useStarknetWallet'
import withSuspense from '@/app/hooks/data/withSuspense'

import SwitchNetworkModal from '../AccountButton/SwitchNetworkModal'

type Props = {
  network: StarknetNetwork
  size?: ButtonSize
} & LayoutProps &
  MarginProps

// TODO: @dappbeast Show override status + exit (for see param)
const ConnectWalletButton = withSuspense(
  ({ size, network, ...styleProps }: Props): JSX.Element => {
    const [isShowModal, setIsShowModal] = useState(false)
    const [networkStr, setNetworkStr] = useState('')
    const { isConnected, connect, isLoading } = useStarknetWallet()

    const variant = false ? 'warning' : 'primary'

    const onClick = async () => {
      const connectResult = await connect()
      if (connectResult) {
        console.log(connectResult)
        setNetworkStr(connectResult)
        setIsShowModal(true)
      }
    }

    return (
      <>
        <Button
          {...styleProps}
          size={size}
          isLoading={isLoading}
          isDisabled={isConnected}
          variant={variant}
          label={'Connect Wallet'}
          onClick={onClick}
        />
        <SwitchNetworkModal
          network={networkStr}
          isOpen={isShowModal}
          onClose={() => {
            setIsShowModal(false)
          }}
        />
      </>
    )
  },
  ({ size, ...styleProps }: Props) => <ButtonShimmer size={size} {...styleProps} />
)

export default ConnectWalletButton
