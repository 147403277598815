import Box from '@lyra/ui/components/Box'
import Button from '@lyra/ui/components/Button'
import Flex from '@lyra/ui/components/Flex'
import Table, { TableCellProps, TableColumn, TableData } from '@lyra/ui/components/Table'
import Text from '@lyra/ui/components/Text'
import { MarginProps } from '@lyra/ui/types'
import formatDate from '@lyra/ui/utils/formatDate'
import formatDateTime from '@lyra/ui/utils/formatDateTime'
import formatNumber from '@lyra/ui/utils/formatNumber'
import { CollateralUpdateEvent, SettleEvent, TradeEvent } from '@lyrafinance/lyra-js'
import { getTokenBalance } from '@optiondance/starknet-sdk'
import React, { useMemo, useState } from 'react'

import { API, Position, SettlementInfo, Trade } from '@/app/api'
import useStarknetWallet from '@/app/hooks/account/useStarknetWallet'
import { useSnContract } from '@/app/hooks/starknet/useSnContract'
import { firstCharUpper } from '@/app/utils'
import filterNulls from '@/app/utils/filterNulls'

import { ToastError } from '../Toast'

// import MarketImage from '../MarketImage'

type Props = {
  positions: Position[]
  status: string
  onClick?: (event: TradeEvent | CollateralUpdateEvent | SettleEvent) => void
  pageSize?: number
} & MarginProps

export type PositionTableData = TableData<{
  position_id: number
  position_num: number
  user_id: string
  instrument_name: string
  side: string
  type: string
  size: number
  funds: number
  margin: number
  exercised_size: number
  average_price: number
  strike_price: number
  option_type: string
  delivery_type: string
  quote_currency: string
  base_currency: string
  expiration_date: Date
  expiration_timestamp: number
  status: number
  settlement: number
  created_at: Date
  updated_at: Date
  position_num_string: string
  initial_funds: number
  trade_list: Trade[]
  productType: string
  statusDescription: string
  buttonType: string
  current_price: string
  pnl: string
  expiryPrice: string
  settlementInfo: SettlementInfo
}>
interface Maps<T> {
  [k: string]: T
}

// history position status
export const HISTORY_POSITION_STATUS: Maps<string> = {
  10: 'Not Exercised',
  20: 'To be settled',
  30: 'Exercised',
}

export function productType(side: string, optionType: string): string {
  if (optionType === 'PUT') {
    if (side === 'ASK') return 'DIP HUNTER'
    else return 'CRASH PROTECTION'
  } else {
    if (side === 'ASK') return 'Bullish Protection'
    else return 'Stable Profit'
  }
}

export function sideType(side: string, optionType: string): string {
  if (optionType === 'PUT') {
    if (side === 'ASK') return 'BUY'
    else return 'SELL'
  } else {
    if (side === 'ASK') return 'SELL'
    else return 'BUY'
  }
}

const PositionTable = ({ positions, onClick, status, pageSize = 10 }: Props) => {
  const [expandedId, setExpandedIds] = useState<Record<string, boolean>>({})
  const { settle, exercise } = useSnContract()
  const { account, wallet } = useStarknetWallet()
  // const {otokenBalance} = useSnBalance()

  function getButtonType(e: Position): string {
    if (e.size === 0) {
      return 'ClosePosition'
    }
    const now = new Date()
    const date = new Date(e.expiration_date)
    const isExpired = now > date
    if (isExpired) {
      if (e.side === 'ASK' && e.status !== 30 && e.settlement === 0) {
        return 'Settle'
      }
      if (e.side == 'BID' && e.status !== 30 && e.settlement === 0) return 'Exercise'
    }
    return 'none'
  }

  const rows: PositionTableData[] = useMemo(() => {
    return positions.map(e => {
      const id = e.position_id ? e.position_id : `${e.instrument_name}-${e.expiration_timestamp}`
      const isExpanded = !!expandedId[id]
      return {
        ...e,
        statusDescription: `${HISTORY_POSITION_STATUS[e.status]}`,
        productType: productType(e.side, e.option_type),
        buttonType: getButtonType(e),
        isExpanded,
        onToggleExpand: (isExpanded: boolean) => {
          setExpandedIds(expandedStrikes => ({
            ...expandedStrikes,
            [id]: isExpanded,
          }))
        },
        isExpandedContentClickable: true,
        expanded: (
          <Box pb={4} px={3}>
            <Text variant="bodyMedium" mb={6}>
              CreatedAt
            </Text>
            {formatDateTime(e.created_at)}
          </Box>
        ),
      }
    })
  }, [expandedId, positions])

  const colOption = {
    // accessor: 'instrument_name',
    canSort: false,
    Header: 'Option',
    // width: 160,
    Cell: (props: TableCellProps<PositionTableData>) => {
      const { strike_price, option_type, base_currency, size, expiration_timestamp } = props.row.original
      const isPut = option_type === 'PUT'
      const isLong = size > 0
      const hideSize = true
      return (
        <Flex alignItems="center">
          {/* <MarketImage market={position.market()} /> */}
          <Box ml={0}>
            <Text>
              {base_currency}&nbsp;${strike_price}&nbsp;
              {!isPut ? 'Call' : 'Put'}
            </Text>
            <Text variant="small" color="secondaryText">
              <Text as="span" color={isLong ? 'primaryText' : 'errorText'}>
                {isLong ? 'Long' : 'Short'}
                {!hideSize ? ` ${formatNumber(size)}` : ''}
              </Text>
              {' · '}
              {formatDate(expiration_timestamp, true)} Exp
            </Text>
          </Box>
        </Flex>
      )
    },
  }
  const colAmount = {
    canSort: false,
    Header: 'Amount',
    width: 80,
    Cell: (props: TableCellProps<PositionTableData>) => {
      const { size } = props.row.original
      return <Text color={'text'}>{Math.abs(size).toFixed(1)}</Text>
    },
  }

  const colTotal = {
    canSort: false,
    Header: 'Total',
    width: 80,
    Cell: (props: TableCellProps<PositionTableData>) => {
      const { size } = props.row.original
      return <Text color={'text'}>{Math.abs(size).toFixed(1)}</Text>
    },
  }

  const colAvgPrice = {
    canSort: false,
    Header: 'Avg Price',
    Cell: (props: TableCellProps<PositionTableData>) => {
      const { average_price, option_type } = props.row.original
      const avgPrice =
        String(average_price) === '-'
          ? '-'
          : option_type === 'CALL'
          ? `${average_price} ${props.row.original.quote_currency}`
          : `$${average_price}`
      return <Text color={'text'}>{avgPrice}</Text>
    },
  }

  const colCurrentPrice = {
    canSort: false,
    Header: 'Current Price',
    Cell: (props: TableCellProps<PositionTableData>) => {
      const { current_price, option_type } = props.row.original
      const currentPrice =
        current_price === '-'
          ? '-'
          : option_type === 'CALL'
          ? `${current_price} ${props.row.original.quote_currency}`
          : `$${current_price}`
      return <Text color={'text'}>{currentPrice}</Text>
    },
  }

  const colPnl = {
    canSort: false,
    Header: 'Profit/Loss',
    Cell: (props: TableCellProps<PositionTableData>) => {
      const { pnl, option_type } = props.row.original
      let pnlPrefix = '-'
      if (Number(pnl) > 0) {
        pnlPrefix = '+'
      }
      const pnlAbs = Math.abs(Number(pnl))
      const pnlString =
        pnl === '-'
          ? pnl
          : option_type === 'CALL'
          ? `${pnlPrefix}${pnlAbs} ${props.row.original.quote_currency}`
          : `${pnlPrefix}$${pnlAbs}`
      return <Text color={Number(pnl) > 0 ? 'primaryText' : 'errorText'}>{pnlString}</Text>
    },
  }

  const colClosedCollateral = {
    canSort: false,
    Header: 'Collateral',
    Cell: (props: TableCellProps<PositionTableData>) => {
      const { pnl, settlement_info, side, quote_currency } = props.row.original
      let refundCollateral = '-'
      if (side === 'ASK') {
        refundCollateral = settlement_info.refundMargin ? `+${settlement_info.refundMargin} ${quote_currency}` : '-'
      }
      return <Text color={'primaryText'}>{refundCollateral}</Text>
    },
  }

  const colClosedPnl = {
    canSort: false,
    Header: 'Profit/Loss',
    Cell: (props: TableCellProps<PositionTableData>) => {
      const { settlement_info, side, quote_currency, option_type } = props.row.original
      let pnl = 0
      const exercised = settlement_info.exercised
      if (exercised) {
        if (side === 'ASK') {
          pnl = -1 * Number(settlement_info.bidEarnings)
        } else {
          pnl = Number(settlement_info.bidEarnings)
        }
      }
      let pnlPrefix = '-'
      if (Number(pnl) > 0) {
        pnlPrefix = '+'
      }
      const pnlAbs = Math.abs(Number(pnl))
      const pnlString =
        pnl === 0
          ? '-'
          : option_type === 'CALL'
          ? `${pnlPrefix}${pnlAbs} ${props.row.original.quote_currency}`
          : `${pnlPrefix}$${pnlAbs}`

      // const refundCollateral = settlement_info.refundMargin ? settlement_info.refundMargin : '-'
      return <Text color={Number(pnl) > 0 ? 'primaryText' : 'errorText'}>{pnlString}</Text>
    },
  }

  const colActions = {
    accessor: 'buttonType',
    canSort: false,
    Header: 'Actions',
    Cell: (props: TableCellProps<PositionTableData>) => {
      const status = props.row.original.status
      return (
        <>
          {props.cell.value === 'ClosePosition' && (
            <Button
              variant={'error'}
              width={150}
              // isOutline={!isSelected}
              label={'ClosePosition'}
              onClick={async e => {
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopPropagation()
              }}
              rightIcon={null}
            />
          )}

          {props.cell.value === 'Settle' && (
            <Button
              variant={'error'}
              width={150}
              // isOutline={!isSelected}
              label={'Settle'}
              onClick={async e => {
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopPropagation()
                const instrumentName = props.row.original.instrument_name
                const resp = await API().getInstrumentByName(instrumentName)
                if (resp.code === 0 && resp.data) {
                  const writerToken = resp.data.writer_token
                  const wtokenBalance = await getTokenBalance(account, writerToken, account.address)
                  if (wtokenBalance.isLessThanOrEqualTo(0)) {
                    ToastError('Insuffient writertoken balance ' + wtokenBalance.toString())
                    return
                  }
                  await settle(writerToken, wtokenBalance.toNumber())
                } else {
                  console.error(`instrument name not found ${instrumentName}`)
                }
              }}
              rightIcon={null}
            />
          )}

          {props.cell.value === 'Exercise' && (
            <Button
              variant={'error'}
              width={150}
              // isOutline={!isSelected}
              label={'Exercise'}
              onClick={async e => {
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopPropagation()
                const instrumentName = props.row.original.instrument_name
                const resp = await API().getInstrumentByName(instrumentName)
                if (resp.code === 0 && resp.data) {
                  const optionToken = resp.data.option_token
                  const optiontokenBalance = await getTokenBalance(account, optionToken, account.address)
                  if (optiontokenBalance.isLessThanOrEqualTo(0)) {
                    ToastError('Insuffient optiontoken balance: ' + optiontokenBalance.toString())
                    return
                  }
                  await exercise(optionToken, optiontokenBalance.toNumber())
                } else {
                  console.error(`instrument name not found ${instrumentName}`)
                }
              }}
              rightIcon={null}
            />
          )}
        </>
      )
    },
  }

  const colStatus = {
    accessor: 'statusDescription',
    canSort: false,
    width: 120,
    Header: 'Status',
    Cell: (props: TableCellProps<PositionTableData>) => {
      return <Text color={'secondaryText'}>{firstCharUpper(props.cell.value)}</Text>
    },
  }

  const colDeliveryPrice = {
    canSort: false,
    Header: 'Closed Price',
    width: 120,
    Cell: (props: TableCellProps<PositionTableData>) => {
      return <Text color={'secondaryText'}>${props.cell.row.original.expiryPrice}</Text>
    },
  }

  const openPositionColumns = useMemo<TableColumn<PositionTableData>[]>(() => {
    return filterNulls([colOption, colAmount, colAvgPrice, colCurrentPrice, colPnl])
  }, [account, exercise, settle])

  const closePositionColumns = useMemo<TableColumn<PositionTableData>[]>(() => {
    return filterNulls([
      colOption,
      colStatus,
      colDeliveryPrice,
      colTotal,
      colClosedCollateral,
      colClosedPnl,
      colActions,
    ])
  }, [account, exercise, settle])

  let columns = openPositionColumns
  if (status === 'closed') {
    columns = closePositionColumns
  }

  if (rows.length === 0) {
    return null
  }

  return <Table width="100%" data={rows} columns={columns} pageSize={pageSize} />
}

export default PositionTable
