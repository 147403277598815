import ToggleButton from '@lyra/ui/components/Button/ToggleButton'
import ToggleButtonItem from '@lyra/ui/components/Button/ToggleButtonItem'
import Card from '@lyra/ui/components/Card'
import CardSection from '@lyra/ui/components/Card/CardSection'
import Center from '@lyra/ui/components/Center'
import Flex from '@lyra/ui/components/Flex'
import Spinner from '@lyra/ui/components/Spinner'
import Text from '@lyra/ui/components/Text'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { API, Order, Position, TransactionLog } from '@/app/api'
import OrderTable from '@/app/components/common/SnHistoryTable/OrderTable'
import PositionTable from '@/app/components/common/SnHistoryTable/PositionTable'
import TransactionLogTable from '@/app/components/common/SnHistoryTable/TransactionLogTable'
import { PageId } from '@/app/constants/pages'
import useStarknetWallet from '@/app/hooks/account/useStarknetWallet'
import withSuspense from '@/app/hooks/data/withSuspense'
import getPagePath from '@/app/utils/getPagePath'

import Page from '../common/Page'
import PageGrid from '../common/Page/PageGrid'

enum HistoryTab {
  Orders = 'orders',
  Hold = 'hold',
  HistoryOrders = 'historyOrders',
  HistoryOptions = 'historyOptions',
  TrasactionLog = 'TrasactionLog',
  Trades = 'Trades',
}

export default function TradeHistoryPageHelper(): JSX.Element {
  const [table, setTable] = useState(HistoryTab.Orders)
  return (
    <Page showBackButton backHref={getPagePath({ page: PageId.TradeIndex })}>
      <PageGrid>
        <Text variant="heading">History</Text>
        <Card overflow="hidden">
          <CardSection noSpacing>
            <Flex>
              <ToggleButton>
                {[
                  // { label: 'Positions', id: HistoryTab.Hold },
                  { label: 'Open Orders', id: HistoryTab.Orders },
                  { label: 'Order History', id: HistoryTab.HistoryOrders },
                  { label: 'Closed Positions', id: HistoryTab.HistoryOptions },
                  // { label: 'Trasaction Log', id: HistoryTab.TrasactionLog },
                  // { label: 'Trades', id: HistoryTab.Trades },
                ].map(item => (
                  <ToggleButtonItem
                    key={item.id}
                    id={item.id}
                    label={item.label}
                    isSelected={table === item.id}
                    onSelect={val => setTable(val)}
                  />
                ))}
              </ToggleButton>
              {/* <DownloadTradeHistory /> */}
            </Flex>
          </CardSection>
          {table === HistoryTab.Orders ? <OrderList status="open" /> : null}
          {table === HistoryTab.HistoryOrders ? <OrderList status="close" /> : null}
          {table === HistoryTab.Hold ? <PositionList status="open" /> : null}
          {table === HistoryTab.HistoryOptions ? <PositionList status="closed" /> : null}
          {table === HistoryTab.TrasactionLog ? <TransactionLogList /> : null}
          {/* {table === HistoryTab.Trades ? <TradeList status="closed" /> : null} */}
        </Card>
      </PageGrid>
    </Page>
  )
}

const OrderList = withSuspense(
  ({ status }: { status: string }) => {
    const [orders, setOrders] = useState<Order[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { account } = useStarknetWallet()
    const [refreshFlag, setRefreshFlag] = useState<number>(0)

    useEffect(() => {
      if (!account) return
      const fetchOrders = async () => {
        setIsLoading(true)
        const orders = await API().listOrders({
          current: 1,
          size: 20,
          status: status,
          userId: account.address,
        })
        setOrders(orders.data.records)
        setIsLoading(false)
      }

      fetchOrders()
    }, [account, status, refreshFlag])

    useEffect(() => {
      const timeout = setTimeout(() => {
        setIsLoading(false)
      }, 5000)

      return () => {
        clearTimeout(timeout)
      }
    }, [])

    const navigate = useNavigate()
    return orders.length > 0 ? (
      <CardSection noPadding>
        <OrderTable
          orders={orders}
          status={status}
          onClick={event => {
            const positionId = event.positionId
            return navigate(
              getPagePath({
                page: PageId.Position,
                network: event.lyra.network,
                marketAddressOrName: event.marketName,
                positionId: positionId,
              })
            )
          }}
          refreshData={() => {
            setRefreshFlag(e => e + 1)
          }}
        />
      </CardSection>
    ) : !isLoading ? (
      <CardSection>
        <Text variant="small" color="secondaryText">
          You have no orders.
        </Text>
      </CardSection>
    ) : (
      <Center height="100%" minHeight={120}>
        <Spinner />
      </Center>
    )
  },
  () => (
    <Center height="100%" minHeight={120}>
      <Spinner />
    </Center>
  )
)

const PositionList = withSuspense(
  ({ status }: { status: string }) => {
    const [positions, setPositions] = useState<Position[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { account } = useStarknetWallet()

    useEffect(() => {
      if (!account) return
      const fetchPositions = async () => {
        setIsLoading(true)
        const orders = await API().listPositions({
          current: 1,
          size: 20,
          status: status,
          userId: account.address,
        })
        setPositions(orders.data.records)
        setIsLoading(false)
      }

      fetchPositions()
    }, [account, status])

    const navigate = useNavigate()
    return positions.length > 0 ? (
      <CardSection noPadding>
        <PositionTable
          status={status}
          positions={positions}
          onClick={event => {
            const positionId = event.positionId
            return navigate(
              getPagePath({
                page: PageId.Position,
                network: event.lyra.network,
                marketAddressOrName: event.marketName,
                positionId: positionId,
              })
            )
          }}
        />
      </CardSection>
    ) : !isLoading ? (
      <CardSection>
        <Text variant="small" color="secondaryText">
          You have no positions.
        </Text>
      </CardSection>
    ) : (
      <Center height="100%" minHeight={120}>
        <Spinner />
      </Center>
    )
  },
  () => (
    <Center height="100%" minHeight={120}>
      <Spinner />
    </Center>
  )
)

const TransactionLogList = withSuspense(
  () => {
    const [positions, setPositions] = useState<TransactionLog[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { account } = useStarknetWallet()

    useEffect(() => {
      if (!account) return
      const fetchPositions = async () => {
        setIsLoading(true)
        const orders = await API().listTransactionLogs({
          current: 1,
          size: 20,
          status: '',
          userId: account.address,
        })
        setPositions(orders.records)
        setIsLoading(false)
      }

      fetchPositions()
    }, [account])

    const navigate = useNavigate()
    return positions.length > 0 ? (
      <CardSection noPadding>
        <TransactionLogTable
          logs={positions}
          onClick={event => {
            const positionId = event.positionId
            return navigate(
              getPagePath({
                page: PageId.Position,
                network: event.lyra.network,
                marketAddressOrName: event.marketName,
                positionId: positionId,
              })
            )
          }}
        />
      </CardSection>
    ) : !isLoading ? (
      <CardSection>
        <Text variant="small" color="secondaryText">
          You have no positions.
        </Text>
      </CardSection>
    ) : (
      <Center height="100%" minHeight={120}>
        <Spinner />
      </Center>
    )
  },
  () => (
    <Center height="100%" minHeight={120}>
      <Spinner />
    </Center>
  )
)

const TradeList = withSuspense(
  ({ status }: { status: string }) => {
    const [positions, setPositions] = useState<Position[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { account } = useStarknetWallet()

    useEffect(() => {
      if (!account) return
      const fetchPositions = async () => {
        setIsLoading(true)
        const orders = await API().listPositions({
          current: 1,
          size: 20,
          status: status,
          userId: account.address,
        })
        setPositions(orders.data.records)
        setIsLoading(false)
      }

      fetchPositions()
    }, [account, status])

    const navigate = useNavigate()
    return positions.length > 0 ? (
      <CardSection noPadding>
        <PositionTable
          positions={positions}
          onClick={event => {
            const positionId = event.positionId
            return navigate(
              getPagePath({
                page: PageId.Position,
                network: event.lyra.network,
                marketAddressOrName: event.marketName,
                positionId: positionId,
              })
            )
          }}
        />
      </CardSection>
    ) : !isLoading ? (
      <CardSection>
        <Text variant="small" color="secondaryText">
          You have no positions.
        </Text>
      </CardSection>
    ) : (
      <Center height="100%" minHeight={120}>
        <Spinner />
      </Center>
    )
  },
  () => (
    <Center height="100%" minHeight={120}>
      <Spinner />
    </Center>
  )
)
