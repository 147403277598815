import Box from '@lyra/ui/components/Box'
import Card from '@lyra/ui/components/Card'
import CardBody from '@lyra/ui/components/Card/CardBody'
import CardSeparator from '@lyra/ui/components/Card/CardSeparator'
import Center from '@lyra/ui/components/Center'
import Flex from '@lyra/ui/components/Flex'
import Icon, { IconType } from '@lyra/ui/components/Icon'
import Text from '@lyra/ui/components/Text'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { DESKTOP_HEADER_NAV_HEIGHT, TRADE_CARD_MIN_HEIGHT, TRADE_CARD_MIN_WIDTH } from '@/app/constants/layout'
import TradeForm from '@/app/containers/trade/TradeForm'
import TradePositionsCard from '@/app/containers/trade/TradePositionsCard'
import TradePriceCard from '@/app/containers/trade/TradePriceCard'
import TradeSimpleBoardCard from '@/app/containers/trade/TradeSimpleBoardCard'
import withSuspense from '@/app/hooks/data/withSuspense'
import PageError from '@/app/page_helpers/common/Page/PageError'
import PageLoading from '@/app/page_helpers/common/Page/PageLoading'
import { Market, OptionQuoteList, StarknetOptionQuote } from '@/app/types'

import { API, Position } from '../api'
import TradeMarketDropdown from '../containers/trade/TradeMarketDropdown'
import useStarknetWallet from '../hooks/account/useStarknetWallet'
import { useSnContract } from '../hooks/starknet/useSnContract'
import Page from '../page_helpers/common/Page'
import PageGrid from '../page_helpers/common/Page/PageGrid'
import { Board, defaultBoard } from '../types'
import { timestampToDateString } from '../utils'

type Props = {
  markets: Market[]
  selectedMarket: Market
}

const markets: Market[] = [
  {
    baseToken: '',
    baseTokenSymbol: 'WBTC',
    quoteToken: '',
    quoteTokenSymbol: 'USDC',
    baseSpotPrice: 0,
  },
  {
    baseToken: '',
    baseTokenSymbol: 'ETH',
    quoteToken: '',
    quoteTokenSymbol: 'USDC',
    baseSpotPrice: 0,
  },
]

// /trade/:network/:marketAddressOrName
export const TradePage = withSuspense(
  () => {
    let baseTokenSymbol = localStorage.getItem('marketBaseTokenSymbol')
    if (!baseTokenSymbol) {
      localStorage.setItem('marketBaseTokenSymbol', 'WBTC')
      baseTokenSymbol = 'WBTC'
    }
    let selectedMarket = markets[0]
    if (baseTokenSymbol === 'ETH') {
      selectedMarket = markets[1]
    }
    return !selectedMarket ? (
      <PageError error="Market does not exist" />
    ) : (
      <TradePageHelper markets={markets} selectedMarket={selectedMarket} />
    )
  },
  () => <PageLoading />
)

export function TradePageHelper({ markets, selectedMarket: _selectedMarket }: Props): JSX.Element {
  const [selectedStrikeId, setSelectedStrikeId] = useState<string | null>(null)
  // const [selectedBoard, setSelectedBoard] = useSelectedBoardSync(selectedMarket) // sync hook
  const [selectedBoard, setSelectedBoard] = useState<Board>(defaultBoard) // sync hook
  const [isCall, setIsCall] = useState(true)
  const [isBuy, setIsBuy] = useState(true)
  const [selectedMarket, setSelectedMarket] = useState<Market>(_selectedMarket)
  // const selectedOption = selectedStrikeId !== null ? defaultOption : null
  const [selectedOption, setSelectedOption] = useState<StarknetOptionQuote | null>(null)
  const [optionQuoteList, setOptionQuoteList] = useState<OptionQuoteList>([])
  const { getOracleSpotMedian } = useSnContract()
  const { account } = useStarknetWallet()
  const [expiryTs, setExpiryTs] = useState<number>(0)

  const isAdvancedMode = false
  const handleTrade = useCallback(
    (market: Market, positionId: number) => {
      setSelectedStrikeId(null)
      if (isAdvancedMode) {
        window.scrollBy({ top: document.body.scrollHeight })
      } else {
        console.log('handle trade')
        // navigate(
        //   getPagePath({
        //     page: PageId.Position,
        //     network: market.lyra.network,
        //     marketAddressOrName: market.name,
        //     positionId,
        //   })
        // )
      }
    },
    [setSelectedStrikeId, isAdvancedMode]
  )

  const handleSelectOption = useCallback(
    (newOption: StarknetOptionQuote, isSelected: boolean) => {
      console.log(newOption)

      const isSelect = selectedStrikeId !== newOption?.instrumentName
      if (isSelect && newOption) {
        setSelectedStrikeId(newOption.instrumentName)
      } else {
        setSelectedStrikeId(null)
      }
      if (isSelected) {
        setSelectedOption(newOption)
      } else {
        setSelectedOption(null)
      }
    },
    [selectedStrikeId]
  )

  const handleToggleBuy = useCallback((newIsBuy: boolean) => {
    setIsBuy(newIsBuy)
  }, [])

  const selectedPosition = useMemo(() => {
    if (selectedOption) {
      return null
    }
  }, [selectedOption])

  const handleChangeMarket = useCallback((market: Market) => {
    setSelectedMarket(market)
    localStorage.setItem('marketBaseTokenSymbol', market.baseTokenSymbol)
  }, [])

  useEffect(() => {
    const fetchMarketByExpiry = async (date: string) => {
      const resp = await API().listMarketsByExpiryDates({
        baseCurrency: selectedMarket.baseTokenSymbol,
        quoteCurrency: 'USDC',
        optionType: isCall ? 'CALL' : 'PUT',
        side: isBuy ? 'ASK' : 'BID',
        expiryDate: date,
      })
      // setSelectedOption(resp.data.instrument)
      setOptionQuoteList(resp.data)
    }
    const date = timestampToDateString(expiryTs * 1000)
    fetchMarketByExpiry(date)
  }, [isBuy, isCall, selectedBoard, expiryTs, selectedMarket.baseTokenSymbol])

  useEffect(() => {
    const fetchSpotPrice = async () => {
      const res = await API().getIndexPrice(selectedMarket.baseTokenSymbol)
      setSelectedMarket(market => {
        return {
          ...market,
          baseSpotPrice: res.data.price,
        }
      })
    }
    fetchSpotPrice()
  }, [ selectedMarket.baseTokenSymbol])

  const selectBoard = (newBoard: Board) => {
    const expiryTs = newBoard.expiryTimestamp
    setExpiryTs(expiryTs)
    newBoard = { ...defaultBoard, expiryTimestamp: expiryTs, id: expiryTs }
    if (newBoard.id !== selectedBoard?.id) {
      setSelectedStrikeId(null)
    }
    setSelectedBoard((board: Board) => {
      board.expiryTimestamp = expiryTs
      return board
    })
  }

  const [positions, setPositions] = useState<Position[]>([])
  const [isOpenPositionLoading, setIsOpenPositionLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!account) return
    const fetchPositions = async () => {
      setIsOpenPositionLoading(true)
      const orders = await API().listPositions({
        current: 1,
        size: 20,
        status: 'open',
        userId: account.address,
      })
      setPositions(orders.data.records)
      setIsOpenPositionLoading(false)
    }

    fetchPositions()
  }, [account])

  return (
    <Page isFullWidth={isAdvancedMode}>
      <PageGrid
        rightColumn={
          <Box minWidth={TRADE_CARD_MIN_WIDTH} maxWidth={TRADE_CARD_MIN_WIDTH}>
            <Box
              minWidth={TRADE_CARD_MIN_WIDTH}
              maxWidth={TRADE_CARD_MIN_WIDTH}
              sx={{ position: 'sticky', top: DESKTOP_HEADER_NAV_HEIGHT }}
              pb={12}
            >
              {selectedOption ? (
                <Card width="100%">
                  <TradeForm isBuy={isBuy} isCall={isCall} option={selectedOption} onTrade={handleTrade} position={selectedPosition} />
                </Card>
              ) : (
                <>
                  <Card mb={6} width="100%" minHeight={TRADE_CARD_MIN_HEIGHT}>
                    <CardBody flexGrow={1}>
                      <Text variant="cardHeading">Select Option</Text>
                      <Center flexGrow={1} flexDirection="column">
                        <Icon icon={IconType.PlusCircle} color="disabledText" size={64} strokeWidth={0.5} />
                        <Text mt={4} color="secondaryText">
                          Select an option
                        </Text>
                      </Center>
                    </CardBody>
                  </Card>
                  {/* <TradeAnnouncementHeaderCard blockTimestamp={selectedMarket.block.timestamp} /> */}
                </>
              )}
            </Box>
          </Box>
        }
      >
        <Flex>
          <TradeMarketDropdown markets={markets} onChangeMarket={handleChangeMarket} selectedMarket={selectedMarket} />
        </Flex>

        <Card>
          <TradePriceCard market={selectedMarket} />
          <CardSeparator />
          <TradePositionsCard openPositions={positions} />
          <CardSeparator />
          <TradeSimpleBoardCard
            isCall={isCall}
            onToggleCall={newIsCall => {
              if (newIsCall !== isCall) {
                // Reset selected option
                setSelectedStrikeId(null)
              }
              setIsCall(newIsCall)
            }}
            isBuy={isBuy}
            onToggleBuy={handleToggleBuy}
            market={selectedMarket}
            selectedBoard={selectedBoard}
            optionQuoteList={optionQuoteList}
            onSetOptionQuoteList={setOptionQuoteList}
            onSelectBoard={selectBoard}
            selectedOption={selectedOption}
            onSelectOption={handleSelectOption}
          />
        </Card>
        {/* {selectedOption && isMobile ? (
          <TradeFormModal
            isOpen
            onClose={() => setSelectedStrikeId(null)}
            onTrade={() => setSelectedStrikeId(null)}
            option={selectedOption}
            position={selectedPosition}
            isBuy={isBuy}
          />
        ) : null} */}
      </PageGrid>
    </Page>
  )
}

export default TradePage
