import type { AxiosInstance } from 'axios'
import axios from 'axios'

import { StarknetOption } from '../types'
import isMainnet from '../utils/isMainnet'


export interface Resp<T> {
  code: number
  data: T
  msg: string
}




export interface PageOrderParams {
  size: number
  current: number
  order?: 'asc' | 'desc'
}

export interface MarketFilterParams {
  baseCurrency: string
  quoteCurrency: string
  optionType: string
  deliveryType?: string
  strikePrice?: string
  expiryDate?: string
  side?: string
}


export type ListDeliveryPricesParams = PageOrderParams | { asset: string }

export function toMarketFilterParams(
  params: URLSearchParams
): MarketFilterParams {
  const ret: MarketFilterParams = {
    baseCurrency: params.get('baseCurrency') ?? '',
    quoteCurrency: params.get('quoteCurrency') ?? '',
    optionType: params.get('optionType') ?? '',
  }
  if (params.get('deliveryType')) {
    ret.deliveryType = params.get('deliveryType') ?? ''
  }
  if (params.get('strikePrice')) {
    ret.strikePrice = params.get('strikePrice') ?? ''
  }
  if (params.get('side')) {
    ret.side = params.get('side') ?? ''
  }
  return ret
}

export interface StarknetCreateOrderReq {
  order: OrderRequest
  sender: string
  orderHash: string
  sig_r: string
  sig_s: string
}

export interface MixinCreateOrderReq {
  side: string
  type: string
  price: string
  amount: string
  instrument_name: string
}

export interface OrderRequest {
  order_id?: string
  user?: string
  instrument: string
  quantity: number
  price: number
  side: string
  type: string
  expiration: number
  salt: string
}



export interface Order {
  order_id?: string;
  order_num: number;
  user_id?: string;
  side: string;
  order_type: string;
  price: string;
  remaining_amount: string;
  filled_amount: string;
  remaining_funds: string;
  filled_funds: string;
  margin: string;
  quote_asset_id: string;
  base_asset_id: string;
  instrument_name: string;
  option_type: string;
  delivery_type: string;
  strike_price: string;
  expiration_timestamp: number;
  expiration_timestampe: number;
  expiration_date: string;
  quote_currency: string;
  base_currency: string;
  order_status: string;
  trade_list: any[];
  created_at: Date
}
export interface SettlementInfo {
  underlyingPrice: string,
  refundMargin: string,
  refundMarginUsd: string,
  size: string,
  bidEarnings: string,
  exercised: boolean,
}

export interface Position {
  position_id:          number;
  position_num:         number;
  user_id:              string;
  instrument_name:      string;
  side:                 string;
  type:                 string;
  size:                 number;
  funds:                number;
  margin:               number;
  exercised_size:       number;
  average_price:        number;
  strike_price:         number;
  option_type:          string;
  delivery_type:        string;
  quote_currency:       string;
  base_currency:        string;
  expiration_date:      Date;
  expiration_timestamp: number;
  status:               number;
  settlement:           number;
  created_at:           Date;
  updated_at:           Date;
  position_num_string:  string;
  initial_funds:        number;
  trade_list:           Trade[];
  expiryPrice:          string;
  settlementInfo:    SettlementInfo;   
}

export interface Trade {
  trade_id:         number;
  user_id:          string;
  liquidity:        string;
  bid_order_id:     number;
  ask_order_id:     number;
  quote_asset_id:   string;
  base_asset_id:    string;
  side:             string;
  price:            string;
  amount:           string;
  instrument_name:  string;
  underlying_price: string;
  status:           string;
  created_at:       Date;
  updated_at:       Date;
}

export interface TransactionLog {
  date: Date
  optionName: string
  type: string
  assetSymbol: string
  amountChange: string
}


export type listParams = { status: string, userId?: string } & PageOrderParams

export interface BaseApi {
  // market
  listStrikePrices(params: MarketFilterParams): any
  listExpiryDates(params: MarketFilterParams): any
  listMarketsByExpiryDates(params: MarketFilterParams): any

  listMarketByStrikePrice(price: number, params: MarketFilterParams): unknown
  //orders
  listOrders(params: listParams): any
  getOrderById(id: string): unknown
  createOrder(req: StarknetCreateOrderReq | MixinCreateOrderReq): any
  cancelOrder(id: string): any
  //positions
  listPositions(params: listParams): any
  getPositionById(id: string): unknown
  listInstruments(params: listParams): unknown
  getInstrumentByName(name: string): Promise<Resp<StarknetOption | null>>
  listDeliveryPrices(params: ListDeliveryPricesParams): unknown
  listTransactionLogs(params: listParams) :any
  listSpotPrices(symbol: string, timerange: string): Promise<any>
  getIndexPrice(symbol: string): Promise<any>
}

export function API(): BaseApi {
  return new OptionStarknetApi()
}

export class OptionStarknetApi implements BaseApi {
  private baseUrl: string
  private client: AxiosInstance

  constructor() {
    const isInMainnet = isMainnet()
    let apiUrl
    if (isInMainnet) {
      apiUrl = process.env.REACT_APP_MAINNET_API_URL
    }else {
      apiUrl = process.env.REACT_APP_TESTNET_API_URL
    }
    if (!apiUrl) {
      throw Error("api endpoint not set")
    }
    this.baseUrl = apiUrl
    this.client = axios.create({
      baseURL: this.baseUrl,
    })
  }
  async listStrikePrices(params: MarketFilterParams) {
    return await this.client.get('/api/v1/market/strike-prices', {
      params: params,
    })
  }

  async listExpiryDates(params: MarketFilterParams) {
    return await this.client.get('/api/v1/market/expiry-dates', {
      params: params,
    })
  }

  async listMarketsByExpiryDates(params: MarketFilterParams) {
    return await this.client.get('/api/v1/market/expiry-date', {
      params: params,
    })
  }

  async listSpotPrices(symbol: string, timerange: string) {
    return await this.client.get('/api/v1/market/p-spot-prices', {
      params: {
        symbol: symbol,
        timerange: timerange,
      },
    })
  }
  async getIndexPrice(symbol: string) {
    if (symbol==='WBTC') {
      symbol = 'BTC'
    }
    return await this.client.get('/api/v1/market/p-index-price', {
      params: {
        symbol: symbol,
      },
    })
  }

  async listMarketByStrikePrice(price: number, params: MarketFilterParams) {
    const resp = await this.client.get(`/api/v1/market/price/${price}`, {
      params: params,
    })
    if (!resp.data) return []
    return resp.data.map((e: any) => ({
      asks: e.asks.map((entry: any) => {
        return {
          price: entry.price,
          amount: entry.amount,
        }
      }),
      bids: e.bids.map((entry: any) => {
        return {
          price: entry.price,
          amount: entry.size,
        }
      }),
      expiration_date: e.expiration,
      expiration_timestamp: Math.floor(new Date(e.expiration).getTime() / 1000),
      instrument_name: e.instrumentName,
      instrument: e.instrument,
      option_type: e.optionType,
      strike_price: e.strikePrice,
    }))
  }

  async listOrders(params: listParams) {
    const resp = await this.client.get('/api/v1/order', {
      params: {
        ...params,
      },
    })
    return resp.data
  }
  async getOrderById(id: string) {
    const resp = await this.client.get('/api/v1/order/' + id)
    return resp.data.data
  }

  async createOrder(req: StarknetCreateOrderReq | MixinCreateOrderReq) {
    return await this.client.post('/api/v1/order/', req)
  }

  async cancelOrder(id: string) {
    const resp = await this.client.post(`/api/v1/order/${id}/cancel`)
    return resp.data
  }

  async listPositions(params: listParams) {
    const resp = await this.client.get('/api/v1/position', {
      params: {
        ...params,
      },
    })
    return resp.data
  }
  async getPositionById(id: string) {
    const resp = await this.client.get('/api/v1/position/' + id)
    return resp.data.data
  }

  async listInstruments(params: PageOrderParams) {
    const resp = await this.client.get('/api/v1/instrument', {
      params: params,
    })
    return resp.data.data
  }

  async getInstrumentByName(name: string): Promise<Resp<StarknetOption>> {
    const resp = await this.client.get('/api/v1/instrument/' + name)
    return resp.data
  }

  async listDeliveryPrices(params: ListDeliveryPricesParams) {
    const resp = await this.client.get('/api/v1/expiry-price', {
      params: params,
    })
    return resp.data.data
  }

  async listTransactionLogs(params: listParams) {
    const resp = await this.client.get('/api/v1/transaction-log', {
      params: params,
    })
    return resp.data.data
  }
}