import Box from '@lyra/ui/components/Box'
import Button from '@lyra/ui/components/Button'
import Card from '@lyra/ui/components/Card'
import CardBody from '@lyra/ui/components/Card/CardBody'
import CardSeparator from '@lyra/ui/components/Card/CardSeparator'
import Center from '@lyra/ui/components/Center'
import Text from '@lyra/ui/components/Text'
import { getAddressBook, newBnWithDecimals, unwrapDecimals } from '@optiondance/starknet-sdk'
import { BigNumber  } from 'bignumber.js'
import React from 'react'

// import React, { useCallback, useEffect, useMemo, useState } from 'react'
import withSuspense from '@/app/hooks/data/withSuspense'
import PageLoading from '@/app/page_helpers/common/Page/PageLoading'

import { toSnSDKNetwork } from '../constants/networks'
import useStarknetWallet, { useSnBalance } from '../hooks/account/useStarknetWallet'
import { useSnContract } from '../hooks/starknet/useSnContract'
import Page from '../page_helpers/common/Page'


// /trade/:network/:marketAddressOrName
export const SnFaucetPage = withSuspense(
  () => {
    return <TradePageHelper />
  },
  () => <PageLoading />
)

export function TradePageHelper(): JSX.Element {
  const { network, account } = useStarknetWallet()
  const snNetwork = toSnSDKNetwork(network)
  const addressbook = getAddressBook(snNetwork)
  const { mintToken } = useSnContract()

  const testTokens = [addressbook.usdc, addressbook.wbtc, addressbook.eth]

  const { balance: usdcBalance, refreshBalance: refreshUsdcBalance } = useSnBalance(addressbook.usdc.id)
  const { balance: wbtcBalance, refreshBalance: refreshWbtcBalance } = useSnBalance(addressbook.wbtc.id)
  const { balance: ethBalance, refreshBalance: refreshEthBalance } = useSnBalance(addressbook.eth.id)

  function getBalance(symbol: string) {
    if (symbol === 'USDC') return Number(unwrapDecimals(usdcBalance, 6).toFixed(6))
    if (symbol === 'ETH') return Number(unwrapDecimals(ethBalance, 18).toFixed(8))
    if (symbol === 'WBTC') return Number(unwrapDecimals(wbtcBalance, 8).toFixed(8))
  }

  function getDefaultMintAmt(symbol: string) {
    if (symbol === 'USDC') return 1000000
    if (symbol === 'ETH') return 1000
    if (symbol === 'WBTC') return 100
    return 10
  }

  async function mintTestToken(address: string, symbol: string, amt: number) {
    let amtCallData = new BigNumber(amt)
    if (symbol === 'USDC') {
        amtCallData = newBnWithDecimals(amt, 6)
    } else if (symbol === 'WBTC') {
        amtCallData = newBnWithDecimals(amt, 8)
    }else {
        amtCallData = newBnWithDecimals(amt, 18)
    }
    console.log(amtCallData.toNumber(), amtCallData.toString(10));
    

    await mintToken(address, account.address, amtCallData)
    await refreshUsdcBalance()
    await refreshWbtcBalance()
    await refreshEthBalance()
  }

  return (
    <Page isFullWidth={false}>
      <Center height="100%" marginTop={-200}>
        <Card minWidth={480}>
          <CardBody>
            <Box mb={6}>
              <Text variant="cardHeading">OptionDance Test Token Faucet</Text>
              <Text color="secondaryText">Drip goerli test tokens</Text>
            </Box>
            <>
              {testTokens.map(e => (
                <Box key={e.id}>
                  <h3>{`${e.symbol} test token`}</h3>
                  <Text marginBottom={4} color="secondaryText">{`balance: ${getBalance(e.symbol)} ${e.symbol}`}</Text>
                  <Button
                    variant="primary"
                    marginBottom={4}
                    label={`Mint ${getDefaultMintAmt(e.symbol)} ${e.symbol}`}
                    onClick={
                        async ()=> {
                            await mintTestToken(e.id, e.symbol, getDefaultMintAmt(e.symbol))
                        }
                    }
                  ></Button>
                  <CardSeparator />
                </Box>
              ))}
            </>
          </CardBody>
        </Card>
      </Center>
    </Page>
  )
}

export default SnFaucetPage
