import '@rainbow-me/rainbowkit/styles.css'

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { StarknetNetwork } from './constants/networks'
// import useNetwork from './hooks/account/useNetwork'
import useStarknetWallet from './hooks/account/useStarknetWallet'
import NotFoundPage from './pages/NotFoundPage'
// import PositionPage from './pages/PositionPage'
import { SnFaucetPage } from './pages/SnFaucet'
import TradeHistoryPage from './pages/TradeHistoryPage'
import TradePage from './pages/TradePage'
// import { getDefaultMarket } from './utils/getDefaultMarket'
import isMainnet from './utils/isMainnet'

export const getDefaultMarket = (network: StarknetNetwork) => {
  return 'wbtc'
}


export default function PageRoutes() {
  const { network } = useStarknetWallet()
  return (
    <Routes>
      <Route index element={<Navigate to="/trade" />} />
      <Route path="/trade" element={<Navigate to={`/trade/${network}/${getDefaultMarket(network)}`} />} />
      <Route path="/trade/:network/:marketAddressOrName" element={<TradePage />} />
      <Route path="/trade/history" element={<TradeHistoryPage />} />
      {/* <Route path="/position/:network/:marketAddressOrName/:positionId" element={<PositionPage />} /> */}
      {isMainnet() ? (
        <>
          {/* <Route path="/leaderboard" element={<Navigate to={`/airdrop/${network}`} />} />
          <Route path="/leaderboard/:network" element={<Navigate to={`/airdrop/${network}`} />} />
          <Route path="/leaderboard/history" element={<Navigate to="/airdrop/history" />} />
          <Route path="/airdrop" element={<Navigate to={`/leaderboard/${network}`} />} /> */}
        </>
      ) : null}
      {!isMainnet() ? <Route path="/faucet" element={<SnFaucetPage />} /> : null}
      {/* <Route path="/storybook" element={<StoryBookPage />} /> */}
      {/* <Route path="/admin" element={<Navigate to={`/admin/${network}/${getDefaultMarket(network)}`} />} /> */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}