import React, { useMemo } from 'react'

import TradeBoardTableOrList from '@/app/components/trade/TradeBoardTableOrList'
import { ONE_BN } from '@/app/constants/bn'
import { Board, defaultQuote, Market, OptionQuoteList, StarknetOptionQuote } from '@/app/types'

type Props = {
  market: Market
  board: Board
  optionQuoteList: OptionQuoteList
  isCall: boolean
  isBuy: boolean
  selectedOption: StarknetOptionQuote | null
  onSelectOption: (option: StarknetOptionQuote, isSelected: boolean) => void
}

const TradeSimpleBoardTable = ({ market,  board, optionQuoteList, isCall, isBuy, selectedOption, onSelectOption }: Props) => {
  const strikeQuotesNullable = {
    callBid: defaultQuote,
    callAsk: defaultQuote,
    putBid: defaultQuote,
    putAsk: defaultQuote,
    strike: ONE_BN,
  }
  // const boardQuotes = useBoardQuotesSync(defaultBoard, size)
  const boardQuotes = [strikeQuotesNullable]
  const optionQuotes = useMemo(
    () =>
      optionQuoteList
        .map(({ bids, asks, instrument, strikePrice }) => {
          return {
            bids,
            asks,
            instrument,
            strikePrice: strikePrice,
          }
        })
        .filter(({ bids, asks }) => !!bids || !!asks)
        .sort((a, b) => a.strikePrice - b.strikePrice),
    [optionQuoteList]
  )
  return (
    <>
      {/* <TradeBoardNoticeSection mt={isMobile ? 6 : 0} mx={6} mb={6} board={board} quotes={optionQuotes} /> */}
      <TradeBoardTableOrList
      market={market}
        board={board}
        // quotes={optionQuotes}
        optionQuoteList={optionQuoteList}
        isBuy={isBuy}
        selectedOption={selectedOption}
        onSelectOption={onSelectOption}
      />
    </>
  )
}

export default TradeSimpleBoardTable
