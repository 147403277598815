import Button from '@lyra/ui/components/Button'
import { LayoutProps, MarginProps } from '@lyra/ui/types'
import {
  getAddressBook,
  MSG_OPTION_CALL,
  MSG_OPTION_PUT,
  newBnWithDecimals,
  parseInstrumentName,
  toInstrumentName,
  toMessageTimestamp,
  unwrapDecimals,
} from '@optiondance/starknet-sdk'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { cairo,CallData } from 'starknet'

import { API, StarknetCreateOrderReq } from '@/app/api'
import { ToastError, ToastSuccess } from '@/app/components/common/Toast'
import { MAX_APPROVE_AMOUNT } from '@/app/constants/bn'
import { toSnSDKNetwork } from '@/app/constants/networks'
import useStarknetWallet, { useSnAllowance, useSnBalance } from '@/app/hooks/account/useStarknetWallet'
import { useSnContract } from '@/app/hooks/starknet/useSnContract'
import { StarknetOptionQuote } from '@/app/types'
import { generateOrderSalt } from '@/app/utils/salt'

import SwitchNetworkModal from '../../common/AccountButton/SwitchNetworkModal'

type Props = {
  isBuy: boolean
  isCall: boolean
  option: StarknetOptionQuote
  orderPrice: number
  orderSize: number
  collateralAmount: string
} & MarginProps &
  Omit<LayoutProps, 'size'>

const SnTradeFormButton = ({ isCall, isBuy, option, orderPrice, orderSize, ...styleProps }: Props) => {
  const { account, isConnected, network, connect } = useStarknetWallet()
  const snNetwork = toSnSDKNetwork(network)
  const addressbook = getAddressBook(snNetwork)
  const { mintOption, signOrderMessage, createOption, getOptionByName, multicall } = useSnContract()
  const [optionInfo, setOptionInfo] = useState<any>(null)
  const [isShowModal, setIsShowModal] = useState(false)
  const [networkStr, setNetworkStr] = useState('')
  const updateOptionInfo = useCallback(async () => {
    const expirytimestamp = Math.floor(new Date(option.expiration).getTime() / 1000)
    const optionName = toInstrumentName(
      option.baseCurrency,
      option.quoteCurrency,
      option.strikePrice,
      expirytimestamp,
      option.optionType
    )
    const optionInfo = await getOptionByName(optionName)
    setOptionInfo(optionInfo)
  }, [getOptionByName, option])

  useEffect(() => {
    const update = async () => {
      await updateOptionInfo()
    }
    update()
  }, [option])

  const isOptionCreated = useMemo(() => {
    const isCreated = optionInfo !== null && optionInfo.name !== ''
    return isCreated
  }, [optionInfo])

  const quoteAsset = useMemo(() => {
    return optionInfo && optionInfo.quoteAsset !== '0x0' ? optionInfo.quoteAsset : ''
  }, [optionInfo])
  const optionToken = useMemo(() => {
    return optionInfo && optionInfo.quoteAsset !== '0x0' ? optionInfo.optionToken : ''
  }, [optionInfo])
  const optionName = useMemo(() => {
    return optionInfo && optionInfo.name !== '' ? optionInfo.name : ''
  }, [optionInfo])

  const collateralAmount = useMemo(() => {
    let collateralAmount
    if (option.optionType === 'PUT') {
      collateralAmount = newBnWithDecimals(orderSize * option.strikePrice, 6)
    } else {
      const decimals = option.quoteCurrency === 'WBTC' ? 8 : 18
      collateralAmount = newBnWithDecimals(orderSize, decimals)
    }
    return collateralAmount
  }, [option.optionType, option.quoteCurrency, option.strikePrice, orderSize])

  const { balance: quoteBalance, refreshBalance: refreshQuoteAssetBalance } = useSnBalance(quoteAsset)
  const { balance: otokenBalance, refreshBalance: refreshOtokenBalance } = useSnBalance(optionToken)

  const {
    allowance: quoteAssetAllowance,
    approve: quoteAssetApprove,
    refreshAllowance: refreshQuoteAssetAllowance,
  } = useSnAllowance(quoteAsset, addressbook.controller)

  const {
    allowance: quoteAssetExAllowance,
    approve: quoteAssetExApprove,
    refreshAllowance: refreshQuoteAssetExAllowance,
  } = useSnAllowance(quoteAsset, addressbook.exchange)

  const {
    allowance: otokenExAllowance,
    approve: otokenExApprove,
    refreshAllowance: refreshOtokenExAllowance,
  } = useSnAllowance(optionToken, addressbook.exchange)

  // seller buttons visible
  const isShowApproveQuoteAssetBtn = useMemo(() => {
    return isOptionCreated && !isBuy && quoteAssetAllowance < Number(collateralAmount)
  }, [collateralAmount, isBuy, isOptionCreated, quoteAssetAllowance])

  const isShowMintOptionBtn = useMemo(() => {
    // if option token balance >= ordersize , not show
    const isOtokenBalanceEnough = unwrapDecimals(otokenBalance, 8).isGreaterThanOrEqualTo(
      newBnWithDecimals(orderSize, 0)
    )
    return (
      !isOtokenBalanceEnough &&
      !isBuy &&
      isOptionCreated
    )
  }, [isBuy, isOptionCreated, orderSize, otokenBalance])

  // buyer buttons visible
  const quoteAssetExAmount = useMemo(() => {
    let quoteAssetExAmount = newBnWithDecimals(Number(orderPrice * orderSize), 0)
    if (!isCall) {
      quoteAssetExAmount = newBnWithDecimals(quoteAssetExAmount, 6)
    } else {
      quoteAssetExAmount = newBnWithDecimals(quoteAssetExAmount, 8)
    }
    return quoteAssetExAmount
  }, [isCall, orderPrice, orderSize])

  const isShowExApproveQuoteAssetBtn = useMemo(() => {
    return isOptionCreated && isBuy && quoteAssetExAllowance < quoteAssetExAmount.toNumber()
  }, [isBuy, isOptionCreated, quoteAssetExAllowance, quoteAssetExAmount])

  //common buttons visible
  const isShowExApproveOtoken = useMemo(() => {
    // if  otoken exchange allowance < otoken balance, show
    console.log('otokenExAllowance', otokenExAllowance, 'otokenBalance', otokenBalance)
    return isOptionCreated && !isBuy && otokenExAllowance < otokenBalance
  }, [isOptionCreated, isBuy, otokenExAllowance, otokenBalance])

  return (
    <>
      {isShowApproveQuoteAssetBtn && isShowMintOptionBtn && (
        <Button
          label={`Approve ${option.quoteCurrency} To Pool`}
          width="100%"
          onClick={async () => {
            await quoteAssetApprove(MAX_APPROVE_AMOUNT)
            refreshQuoteAssetAllowance()
          }}
          variant="primary"
          size="lg"
          mb={3}
        />
      )}

      {isShowMintOptionBtn && (
        <Button
          label={`Mint Option`}
          width="100%"
          onClick={async () => {
            const otokenAmount = newBnWithDecimals(orderSize, 8)
            // await mintOption(optionToken, otokenAmount.toNumber())
            // use multicall to combine 3 seller mint option process,
            // 1. approve quote asset to controller
            // 2. mint_option
            // 3. approve optiontoken to exchange
            const calldataArray = [
              {
                contractAddress: quoteAsset,
                entrypoint: "approve",
                calldata:  CallData.compile({
                  spender: addressbook.controller,
                  amount: cairo.uint256(collateralAmount.toString()),
                }) 
              },
              {
                contractAddress: addressbook.controller,
                entrypoint: "mint_option",
                calldata:  CallData.compile({
                  option_token: optionToken,
                  amount: cairo.uint256(otokenAmount.toNumber()),
                }),
              },
              {
                contractAddress: optionToken,
                entrypoint: "approve",
                calldata:  CallData.compile({
                  spender: addressbook.exchange,
                  amount: cairo.uint256(otokenAmount.toNumber()),
                }),
              }
            ]
            await multicall(calldataArray) 
            refreshQuoteAssetAllowance()
            refreshOtokenBalance()
            refreshOtokenExAllowance()
          }}
          variant="primary"
          size="lg"
          mb={3}
        />
      )}

      {isShowExApproveOtoken && (
        <Button
          label={`Approve OptionToken To Exchange`}
          width="100%"
          onClick={async () => {
            // const otokenApproveAmount = newBnWithDecimals(orderSize, 8)
            await otokenExApprove(otokenBalance.toString())
            refreshOtokenExAllowance()
          }}
          variant="primary"
          size="lg"
          mb={3}
        />
      )}

      {isShowExApproveQuoteAssetBtn && (
        <Button
          label={`Approve ${option.quoteCurrency} To Exchange`}
          width="100%"
          onClick={async () => {
            await quoteAssetExApprove(quoteAssetExAmount.toString())
            refreshQuoteAssetAllowance()
          }}
          variant="primary"
          size="lg"
          mb={3}
        />
      )}

      {!isConnected && (
        <div>
          <Button
            label={`Connect Wallet`}
            width="100%"
            onClick={async () => {
              const connectResult = await connect()
              if (connectResult) {
                setNetworkStr(connectResult)
                setIsShowModal(true)
              }
            }}
            variant="primary"
            size="lg"
            mb={3}
          />
          <SwitchNetworkModal
            network={networkStr}
            isOpen={isShowModal}
            onClose={() => {
              setIsShowModal(false)
            }}
          />
        </div>
      )}

      {isConnected && !isOptionCreated && (
        <Button
          label={`Create Option`}
          width="100%"
          onClick={async () => {
            let underylingAsset = addressbook.wbtc.id
            if (option.baseCurrency === 'ETH') {
              underylingAsset = addressbook.eth.id
            }
            let quoteAsset = addressbook.usdc.id
            if (option.optionType === 'CALL') {
              quoteAsset = underylingAsset
            }
            const strikeprice = newBnWithDecimals(option.strikePrice, 8)
            let optionTypeCalldata = MSG_OPTION_PUT
            if (option.optionType === 'CALL') {
              optionTypeCalldata = MSG_OPTION_CALL
            }
            const expiry = new Date(option.expiration)
            expiry.setUTCHours(8)
            const expirytimestamp = Math.floor(expiry.getTime() / 1000)

            await createOption(underylingAsset, quoteAsset, strikeprice.toNumber(), expirytimestamp, optionTypeCalldata)
            await updateOptionInfo()
          }}
          variant="primary"
          size="lg"
          mb={3}
        />
      )}

      {isOptionCreated && (
        <Button
          label={`Submit Order`}
          width="100%"
          onClick={async () => {
            if (orderSize.toString() === '0') {
              ToastError('please input contract size')
              return
            }
            //sign order message
            const user = account.address
            const messageSide = isBuy === true ? 'b' : 's'
            const otoken = {
              id: optionToken,
              name: `o${optionName}`,
              symbol: `o${optionName}`,
              decimals: 8,
            }
            const messagePrice = Number(orderPrice)
            if (messagePrice <= 0) {
              console.error(`invalid price : ${messagePrice}`)
              return
            }

            const instrument = parseInstrumentName(optionName, snNetwork)
            const expiration = toMessageTimestamp(instrument.expiry)
            const salt = generateOrderSalt()
            const [message, orderhash] = await signOrderMessage(
              optionName,
              otoken,
              messageSide,
              messagePrice,
              orderSize,
              String(expiration),
              salt
            )
            const starknetCreateOrderReq: StarknetCreateOrderReq = {
              order: {
                user: user,
                instrument: optionName,
                quantity: orderSize,
                price: orderPrice,
                side: isBuy ? 'BID' : 'ASK',
                type: 'LIMIT',
                expiration: Number(message.order.expiration),
                salt: salt,
              },
              sender: String(user),
              orderHash: orderhash,
              sig_r: message.sig_r.toString(),
              sig_s: message.sig_s.toString(),
            }
            // return
            const resp = await API().createOrder(starknetCreateOrderReq)
            if (resp.data.code === 0 || !resp.data.msg) {
              ToastSuccess('Create order successful')
            } else {
              ToastError(resp.data.msg)
            }
          }}
          variant="primary"
          size="lg"
          mb={3}
        />
      )}
    </>
  )
}

export default SnTradeFormButton
