import Box from '@lyra/ui/components/Box'
import Button from '@lyra/ui/components/Button'
import Flex from '@lyra/ui/components/Flex'
import Table, { TableCellProps, TableColumn, TableData } from '@lyra/ui/components/Table'
import Text from '@lyra/ui/components/Text'
import { MarginProps } from '@lyra/ui/types'
import formatDate from '@lyra/ui/utils/formatDate'
import formatDateTime from '@lyra/ui/utils/formatDateTime'
import { CollateralUpdateEvent, SettleEvent, TradeEvent } from '@lyrafinance/lyra-js'
import React, { useMemo, useState } from 'react'

import { API, Order } from '@/app/api'
import { firstCharUpper } from '@/app/utils'
import filterNulls from '@/app/utils/filterNulls'

import { ToastSuccess } from '../Toast'
import { productType, sideType } from './PositionTable'

type Props = {
  orders: Order[]
  status: string
  onClick?: (event: TradeEvent | CollateralUpdateEvent | SettleEvent) => void
  pageSize?: number
  refreshData: () => void
} & MarginProps

export type OrderTableData = TableData<{
  order_id?: string
  order_num: number
  user_id?: string
  side: string
  order_type: string
  price: string
  remaining_amount: string
  filled_amount: string
  remaining_funds: string
  filled_funds: string
  total_amount: string
  margin: string
  quote_asset_id: string
  base_asset_id: string
  instrument_name: string
  option_type: string
  delivery_type: string
  strike_price: string
  expiration_timestamp: number
  expiration_date: string
  quote_currency: string
  base_currency: string
  order_status: string
  productType: string
  statusDescription: string
  created_at: Date
}>

const OrderTable = ({ orders, status, pageSize = 10, refreshData }: Props) => {
  const [expandedId, setExpandedIds] = useState<Record<string, boolean>>({})

  const rows: OrderTableData[] = useMemo(() => {
    return orders.map(e => {
      const id = e.order_id ? e.order_id : `${e.instrument_name}-${e.expiration_timestamp}`
      const isExpanded = !!expandedId[id]
      return {
        ...e,
        expiration_timestamp: e.expiration_timestampe,
        total_amount: String(Number(e.filled_amount) + Number(e.remaining_amount)),
        productType: productType(e.side, e.option_type),
        statusDescription: `${sideType(e.side, e.option_type)} ${e.base_currency} - ${e.order_status}`,
        isExpanded,
        onToggleExpand: (isExpanded: boolean) => {
          setExpandedIds(expandedStrikes => ({
            ...expandedStrikes,
            [id]: isExpanded,
          }))
        },
        isExpandedContentClickable: false,
        // expanded: (
        //   <Box pb={4} px={3}>
        //     <Text variant="bodyMedium" mb={6}>
        //       CreatedAt
        //     </Text>
        //     {formatDateTime(e.created_at)}
        //   </Box>
        // ),
      }
    })
  }, [expandedId, orders])

  const columns = useMemo<TableColumn<OrderTableData>[]>(() => {
    let cols: TableColumn<OrderTableData>[] = [
      {
        accessor: 'instrument_name',
        Header: 'Option',
        width: 160,
        Cell: (props: TableCellProps<OrderTableData>) => {
          const { strike_price, side, option_type, base_currency, expiration_timestamp } = props.row.original
          const isPut = option_type === 'PUT'
          const isLong = side === 'BID'
          return (
            <Flex alignItems="center">
              {/* <MarketImage market={position.market()} /> */}
              <Box ml={0}>
                <Text>
                  {base_currency}&nbsp;${strike_price}&nbsp;
                  {!isPut ? 'Call' : 'Put'}
                </Text>
                <Text variant="small" color="secondaryText">
                  <Text as="span" color={isLong ? 'primaryText' : 'errorText'}>
                    {isLong ? 'Long' : 'Short'}
                    {/* {!hideSize ? ` ${formatNumber(size)}` : ''} */}
                  </Text>
                  {' · '}
                  {formatDate(expiration_timestamp, true)} Exp
                </Text>
              </Box>
            </Flex>
          )
        },
      },

      {
        accessor: 'price',
        Header: 'Price',
        width:100,
        Cell: (props: TableCellProps<OrderTableData>) => {
          const {option_type, quote_currency, price } = props.row.original
          let prefix = '$'
          let suffix = ''
          if (option_type === 'CALL') {
            prefix = ''
            suffix = quote_currency
          }
          return (
            <Text color={'secondaryText'}>
                {`${prefix}${props.cell.value}${suffix}`}
            </Text>
          )
        },
      },

      {
        Header: 'Side',
        Cell: (props: TableCellProps<OrderTableData>) => {
          const { side } = props.row.original
          return <Text color={'secondaryText'}>{side === 'BID' ? 'Buy' : 'Sell'}</Text>
        },
        width: 80
      },
      {
        Header: 'Completed',
        Cell: (props: TableCellProps<OrderTableData>) => {
          const { filled_amount } = props.row.original
          return <Text color={'secondaryText'}>{Number(filled_amount).toFixed(1)}</Text>
        },
        width: 80
      },
      {
        Header: 'Total',
        Cell: (props: TableCellProps<OrderTableData>) => {
          const { total_amount } = props.row.original
          return <Text color={'secondaryText'}>{Number(total_amount).toFixed(1)}</Text>
        },
        width: 100
      },

      {
        accessor: 'order_status',
        Header: 'Status',
        Cell: (props: TableCellProps<OrderTableData>) => {
          return <Text color={'secondaryText'}>{firstCharUpper(props.cell.value)}</Text>
        },
      },
      {
        accessor: 'created_at',
        Header: 'Date',
        Cell: (props: TableCellProps<OrderTableData>) => {
          return <Text color={'secondaryText'}>{formatDateTime(props.cell.value)}</Text>
        },
      },
      {
        accessor: 'order_id',
        Header: 'Order Id',
        Cell: (props: TableCellProps<OrderTableData>) => {
          return <Text color={'secondaryText'}>{props.cell.value}</Text>
        },
      },
    ]
    if (status === 'open') {
      cols = cols.concat({
        Header: 'Actions',
        Cell: (props: TableCellProps<OrderTableData>) => {
          const status = props.row.original.order_status
          return (
            <>
              {status === 'open' && (
                <Button
                  variant={'error'}
                  width={150}
                  // isOutline={!isSelected}
                  label={'Cancel'}
                  onClick={async e => {
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopPropagation()
                    const orderId = props.row.original.order_id
                    if (orderId) {
                      const res = await API().cancelOrder(orderId)
                      if (res && res.order_status === 'cancelled') {
                        ToastSuccess('Cancel order successfully')
                        refreshData()
                      }
                    }
                  }}
                />
              )}
            </>
          )
        },
      })
    }
    return filterNulls(cols)
  }, [refreshData, status])

  if (rows.length === 0) {
    return null
  }

  return <Table width="100%" data={rows} columns={columns} pageSize={pageSize} />
}

export default OrderTable
