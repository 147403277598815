import NumberInput from '@lyra/ui/components/Input/NumberInput'
import { LayoutProps, MarginProps } from '@lyra/ui/types'
import React from 'react'

// import { MAX_BN, ZERO_BN } from '@/app/constants/bn'
import { defaultPosition,Trade } from '@/app/types'

type Props = {
  trade: Trade
  size: number
  onChangeSize: (size: number) => void
} & MarginProps &
  Omit<LayoutProps, 'size'>

const TradeFormSizeInput = ({ trade, size, onChangeSize, ...styleProps }: Props) => {
  const isBuy = trade.isBuy
  const position = defaultPosition
  const isOpen = isBuy === position?.isLong
  const sizeWithDefaults =  0
  return (
    <NumberInput
      {...styleProps}
      value={size}
      decimals={0}
      onChange={onChangeSize}
      placeholder={sizeWithDefaults}
      // max={position && !isOpen ? position.size : 999999}
      // min={0}
      textAlign="right"
      showMaxButton={!!position && !isOpen}
      onBlur={() => {
        return
      }}
    />
  )
}

export default TradeFormSizeInput
