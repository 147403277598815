import getAssetSrc from '@/app/utils/getAssetSrc'

import { StarknetNetwork } from '../constants/networks'


export default function getSnNetworkLogoURI(network: StarknetNetwork): string {
  switch (network) {
    case StarknetNetwork.SNGOERLI:
      return getAssetSrc('/images/wallet_testnet.svg')
    case StarknetNetwork.SNMAIN:
      return getAssetSrc('/images/wallet_mainnet.svg')
  }
}
