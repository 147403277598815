import { IconType } from '@lyra/ui/components/Icon'
import Spinner from '@lyra/ui/components/Spinner'
import { createToast, updateToast } from '@lyra/ui/components/Toast'
import { getTokenAllowance, getTokenBalance, hexFeltToString, setTokenApproval } from '@optiondance/starknet-sdk'
import { connect as connectStarknet } from 'get-starknet'
import { getStarknet, StarknetWindowObject } from 'get-starknet-core'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { getSnExplorerTxUrl, StarknetNetwork } from '@/app/constants/networks'

const SnWalletContext = createContext<any>({})

export const SnWalletProvider = ({ children }: { children: ReactNode }) => {
  const defaultNetwork = StarknetNetwork.SNGOERLI
  const [wallet, setWallet] = useState<StarknetWindowObject | null | undefined>(null)
  const [network, setNetwork] = useState<StarknetNetwork>(StarknetNetwork.SNGOERLI)

  useEffect(() => {
    const localNetwork = localStorage.getItem('sn_network')
    const snNetwork = localNetwork === 'SN_MAIN' ? StarknetNetwork.SNMAIN : defaultNetwork
    localStorage.setItem('sn_network', snNetwork)
    setNetwork(snNetwork)
  }, [defaultNetwork])

  useEffect(() => {
    const getWallet = async () => {
      const sn = getStarknet()
      const wallet = await sn.getLastConnectedWallet()
      await wallet?.enable()
      setWallet(wallet)
    }
    getWallet()
  }, [])

  return (
    <SnWalletContext.Provider
      value={{
        wallet,
        setWallet,
        network,
        setNetwork,
      }}
    >
      {children}
    </SnWalletContext.Provider>
  )
}

export function useSnWalletContext() {
  return useContext(SnWalletContext)
}

interface SnWallet {
  isConnected: boolean
  connect: any
  isLoading: boolean
  account: any
  updateNetwork: any
  network: StarknetNetwork
  wallet: any
  disconnect: any
}

function getWalletChainId(wallet: any): string {
  let gotNetwork = wallet?.chainId
  if (!gotNetwork) {
    gotNetwork = hexFeltToString(wallet?.provider.provider.chainId)
  }
  console.log('gotNetwork', gotNetwork);
  return gotNetwork
}

export default function useStarknetWallet(): SnWallet {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { wallet, setWallet, network, setNetwork } = useSnWalletContext()

  useEffect(() => {
    const network = localStorage.getItem('sn_network')
    if (wallet && getWalletChainId(wallet) !== network) {
      setWallet(null)
    }
  }, [wallet, network, setWallet])

  const isConnected = useMemo(() => {
    // const sn = getStarknet()
    // const wallet = await sn.getLastConnectedWallet()
    let isConnected = false
    if (wallet && wallet.account && wallet.isConnected) {
      isConnected = true
    }
    return isConnected
  }, [wallet])

  const account = useMemo(() => {
    return wallet?.account
  }, [wallet])

  const updateNetwork = useCallback(
    async (network: StarknetNetwork) => {
      setNetwork(network)
      localStorage.setItem('sn_network', network)
    },
    [setNetwork]
  )

  const connect = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await connectStarknet({ modalMode: 'alwaysAsk' })
      const sn = getStarknet()
      const expectedNetwork = localStorage.getItem('sn_network')
      const gotNetwork = getWalletChainId(res)
      if (gotNetwork !== expectedNetwork) {
        setIsLoading(false)
        return expectedNetwork
      }

      if (res) {
        const wallet = await sn.enable(res, { starknetVersion: 'v5' })
        setWallet(wallet)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
    return ''
  }, [setWallet])

  const disconnect = useCallback(async () => {
    setWallet(null)
  }, [setWallet])

  return {
    isConnected,
    connect,
    isLoading,
    account,
    updateNetwork,
    network,
    wallet,
    disconnect,
  }
}

export const useSnBalance = (tokenAddress: string) => {
  const { account } = useStarknetWallet()
  const [balance, setBalance] = useState<number>(0)

  const refreshBalance = useCallback(async () => {
    if (!account || !account.address || !tokenAddress) {
      return
    }
    const balance = await getTokenBalance(account, tokenAddress, account.address)
    setBalance(balance.toNumber())
  }, [account, tokenAddress])

  useEffect(() => {
    refreshBalance()
  }, [account, refreshBalance, tokenAddress])

  if (!tokenAddress) {
    return {
      balance: 0,
      refreshBalance,
    }
  }

  return {
    balance,
    refreshBalance,
  }
}

export const useSnAllowance = (tokenAddress: string, spender: string) => {
  const { account, wallet, network } = useStarknetWallet()
  const [allowance, setAllowance] = useState(0)
  const ToastSpinner = () => <Spinner size={20} />

  const refreshAllowance = useCallback(async () => {
    if (!account || !tokenAddress) {
      return
    }
    const allowance = await getTokenAllowance(account, tokenAddress, account.address, spender)
    setAllowance(allowance.toNumber())
  }, [account, spender, tokenAddress])

  useEffect(() => {
    refreshAllowance()
  }, [account, refreshAllowance, spender, tokenAddress])

  const approve = useCallback(
    async (approveAmount: string) => {
      if (!account) {
        return
      }
      if (!spender) {
        throw new Error('Unkown Spender')
      }
      if (!wallet) {
        throw new Error('wallet not connected')
      }

      const toastId = createToast({
        variant: 'info',
        icon: <ToastSpinner />,
        description: 'Confirm your transaction',
        autoClose: false,
      })

      try {
        setTokenApproval(account, wallet.provider, tokenAddress, spender, approveAmount, {
          onCompleted: (txhash: string) => {
            console.log('approve complete', txhash)
            updateToast(toastId, {
              variant: 'success',
              description: 'Your transaction was successful.',
              href: getSnExplorerTxUrl(network, txhash),
              hrefLabel: 'View on starkscan',
              target: '_blank',
              autoClose: 5000,
              icon: IconType.Check,
            })
            refreshAllowance()
          },
          onPending: (txhash: string) => {
            console.log('approve onPending', txhash)
            updateToast(toastId, {
              variant: 'info',
              icon: <ToastSpinner />,
              description: 'Your transaction is pending.',
              href: getSnExplorerTxUrl(network, txhash),
              hrefLabel: 'View on starkscan',
              target: '_blank',
              autoClose: false,
            })
          },
          onError: (txhash: string) => {
            console.log('approve onError', txhash)
            updateToast(toastId, {
              variant: 'error',
              icon: IconType.AlertTriangle,
              description: 'Your transaction is failed.',
              href: getSnExplorerTxUrl(network, txhash),
              hrefLabel: 'View on starkscan',
              target: '_blank',
              autoClose: 3000,
            })
          },
        })
      } catch (error: any) {
        updateToast(toastId, {
          variant: 'error',
          icon: IconType.AlertTriangle,
          description: 'Your transaction is failed.',
          hrefLabel: 'View on starkscan',
          target: '_blank',
          autoClose: 3000,
        })
      }
    },
    [account, spender, wallet, tokenAddress, network, refreshAllowance]
  )

  if (!tokenAddress) {
    return {
      allowance: 0,
      refreshAllowance,
      approve,
    }
  }

  return {
    allowance,
    refreshAllowance,
    approve,
  }
}
