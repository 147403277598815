import Table, { TableCellProps, TableColumn, TableData } from '@lyra/ui/components/Table'
import Text from '@lyra/ui/components/Text'
import { MarginProps } from '@lyra/ui/types'
import formatDateTime from '@lyra/ui/utils/formatDateTime'
import { CollateralUpdateEvent, SettleEvent, TradeEvent } from '@lyrafinance/lyra-js'
import React, { useMemo } from 'react'

import { TransactionLog } from '@/app/api'
import filterNulls from '@/app/utils/filterNulls'

type Props = {
  logs: TransactionLog[]
  onClick?: (event: TradeEvent | CollateralUpdateEvent | SettleEvent) => void
  pageSize?: number
} & MarginProps

export type TransactionLogTableData = TableData<{
  date: Date
  optionName: string
  type: string
  assetSymbol: string
  amountChange: string
}>

// transaction log tables

const TransactionLogTable = ({ logs, pageSize = 20 }: Props) => {
  const rows: TransactionLogTableData[] = useMemo(() => {
    return logs.map(e => {
      return {
        ...e,
      }
    })
  }, [logs])

  const columns = useMemo<TableColumn<TransactionLogTableData>[]>(() => {
    return filterNulls([
      {
        accessor: 'optionName',
        Header: 'Option',
        width: 200,
        Cell: (props: TableCellProps<TransactionLogTableData>) => {
          return <Text color={'secondaryText'}>{props.cell.value}</Text>
        },
      },
      {
        accessor: 'type',
        Header: 'Type',
        Cell: (props: TableCellProps<TransactionLogTableData>) => {
          return <Text color={'secondaryText'}>{props.cell.value}</Text>
        },
      },
      {
        accessor: 'assetSymbol',
        Header: 'AssetSymbol',
        Cell: (props: TableCellProps<TransactionLogTableData>) => {
          return <Text color={'secondaryText'}>{props.cell.value}</Text>
        },
      },
      {
        accessor: 'amountChange',
        Header: 'AmountChange',
        Cell: (props: TableCellProps<TransactionLogTableData>) => {
          return <Text color={'secondaryText'}>{props.cell.value}</Text>
        },
      },
      {
        accessor: 'date',
        Header: 'Date',
        Cell: (props: TableCellProps<TransactionLogTableData>) => {
          return <Text color={'secondaryText'}>{formatDateTime(props.cell.value)}</Text>
        },
      },
    ])
  }, [])

  if (rows.length === 0) {
    return null
  }

  return <Table width="100%" data={rows} columns={columns} pageSize={pageSize} />
}

export default TransactionLogTable
