import { BigNumber } from '@ethersproject/bignumber'
import { QuoteDisabledReason, TradeCollateral } from '@lyrafinance/lyra-js'

import { ONE_BN } from './constants/bn'
export interface Board {
    id: number
    expiryTimestamp: number
    tradingCutoffTimestamp: number
    isExpired: boolean
    isTradingCutoff: boolean
    timeToExpiry: number
    timeToTradingCutoff: number
    spotPriceAtExpiry?: BigNumber
    spotPrice: BigNumber
    baseIv: BigNumber
    isPaused: boolean
}

export interface Market {
    baseToken: string
    baseTokenSymbol: string
    quoteToken: string
    quoteTokenSymbol: string
    baseSpotPrice: number
}


export const defaultMarket: Market = {
    baseToken: "",
    baseTokenSymbol: "WBTC",
    quoteToken: "",
    quoteTokenSymbol: "USDC",
    baseSpotPrice: 0
}

export const defaultBoard: Board = {
    id: 1,
    expiryTimestamp: new Date().getTime(),
    tradingCutoffTimestamp: new Date().getTime(),
    isExpired: false,
    isTradingCutoff: false,
    timeToExpiry: 12312,
    timeToTradingCutoff: 12123,
    spotPriceAtExpiry: ONE_BN,
    baseIv: ONE_BN,
    isPaused: false,
    spotPrice: ONE_BN
}


export interface StarknetOption {
    id: number
    name: string
    option_token: string
    writer_token: string
    underlying_asset: string
    quote_asset: string
    strike_price: number
    expiry_timestamp: number
    option_type: string
    created_at: Date
    updated_at: Date
}


export interface Entry {
    price: string
    size: string
}

export interface StarknetOptionQuote {
    instrument: StarknetOption
    instrumentName: string
    strikePrice: number,
    expiration: Date,
    optionType: string
    quoteCurrency: string
    baseCurrency: string
    bids: Entry[]
    asks: Entry[]
}
export type OptionQuoteList = StarknetOptionQuote[] 


export interface Option {
    isCall: boolean
    strikeId: number
    strikePrice: BigNumber
    price: BigNumber
    spotPrice: BigNumber
    longOpenInterest: BigNumber
    shortOpenInterest: BigNumber
    delta: BigNumber
    theta: BigNumber
    rho: BigNumber
    isInTheMoney: boolean
    marketAddress: string
}

export const defaultOption: Option = {
    isCall: false,
    price: ONE_BN,
    spotPrice: ONE_BN,
    strikePrice: ONE_BN,
    strikeId: 1,
    longOpenInterest: ONE_BN,
    shortOpenInterest: ONE_BN,
    delta: ONE_BN,
    theta: ONE_BN,
    rho: ONE_BN,
    isInTheMoney: false,
    marketAddress: 'fasdfasd'
}


export interface Quote {
    marketName: string
    marketAddress: string
    expiryTimestamp: number
    boardId: number
    strikePrice: BigNumber
    strikeId: number
    isCall: boolean
    isBuy: boolean
    size: BigNumber
    pricePerOption: BigNumber
    premium: BigNumber
    fee: BigNumber
    iv: BigNumber
    fairIv: BigNumber
    forceClosePenalty: BigNumber
    isForceClose: boolean
    breakEven: BigNumber
    toBreakEven: BigNumber
    spotPrice: BigNumber
    isDisabled: boolean
    disabledReason: QuoteDisabledReason
}


export const defaultQuote: Quote = {
    marketName: 'string',
    marketAddress: 'string',
    expiryTimestamp: new Date().getTime(),
    boardId: 12,
    strikePrice: ONE_BN,
    strikeId: 12123,
    isCall: false,
    isBuy: true,
    size: ONE_BN,
    pricePerOption: ONE_BN,
    premium: ONE_BN,
    fee: ONE_BN,
    iv: ONE_BN,
    fairIv: ONE_BN,
    forceClosePenalty: ONE_BN,
    isForceClose: false,
    breakEven: ONE_BN,
    toBreakEven: ONE_BN,
    spotPrice: ONE_BN,
    isDisabled: false,
    disabledReason: QuoteDisabledReason.DeltaOutOfRange,
}



export type OptionQuotesNullable = {
    bid: Quote | null
    ask: Quote | null
    option: Option
}


export enum PositionState {
    Empty,
    Active,
    Closed,
    Liquidated,
    Settled,
    Merged,
}

export type Position = {
    id: number
    marketName: string
    marketAddress: string
    strikeId: number
    strikePrice: BigNumber
    expiryTimestamp: number
    owner: string
    size: BigNumber
    isCall: boolean
    isLong: boolean
    collateral?: BigNumber
    state: PositionState
    isOpen: boolean
    isLiquidated: boolean
    isSettled: boolean
    pricePerOption: BigNumber
    spotPriceAtExpiry?: BigNumber
    isInTheMoney: boolean
    delta: BigNumber
    openTimestamp: number
    closeTimestamp?: number | null
}


export const defaultPosition: Position = {
    id: 1,
    marketName: 'string',
    marketAddress: 'string',
    strikeId: 12,
    strikePrice: ONE_BN,
    expiryTimestamp: new Date().getTime(),
    owner: 'string',
    size: ONE_BN,
    isCall: true,
    isLong: false,
    state: PositionState.Active,
    isOpen: true,
    isLiquidated: false,
    isSettled: false,
    pricePerOption: ONE_BN,
    spotPriceAtExpiry: ONE_BN,
    isInTheMoney: false,
    delta: ONE_BN,
    openTimestamp: 1,
}




export interface Trade {
    marketName: string
    marketAddress: string
    expiryTimestamp: number
    boardId: number
    strikePrice: BigNumber
    strikeId: number
    isCall: boolean
    positionId?: number
    isBuy: boolean
    isOpen: boolean
    isLong: boolean
    owner: string
    size: BigNumber
    newSize: BigNumber
    prevSize: BigNumber
    pricePerOption: BigNumber
    premium: BigNumber
    quoted: BigNumber
    fee: BigNumber
    collateral?: TradeCollateral
    iv: BigNumber
    fairIv: BigNumber
    slippage: number
    // baseToken: TradeToken
    // quoteToken: TradeToken
    forceClosePenalty: BigNumber
    spotPrice: BigNumber
    isCollateralUpdate: boolean
    isForceClose: boolean
    isDisabled: boolean
    pnl: BigNumber,
}


export const defaultTrade: Trade = {
    marketName: 'string',
    marketAddress: 'string',
    expiryTimestamp: new Date().getTime(),
    boardId: 121,
    strikePrice: ONE_BN,
    strikeId: 12,
    isCall: false,
    positionId: 123312,
    isBuy: false,
    isOpen: true,
    isLong: true,
    owner: 'fdas',
    size: ONE_BN,
    newSize: ONE_BN,
    prevSize: ONE_BN,
    pricePerOption: ONE_BN,
    premium: ONE_BN,
    quoted: ONE_BN,
    fee: ONE_BN,
    iv: ONE_BN,
    fairIv: ONE_BN,
    slippage: 0.1,
    // baseToken: 123
    // quoteToken: TradeToken
    forceClosePenalty: ONE_BN,
    spotPrice: ONE_BN,
    isCollateralUpdate: false,
    isForceClose: false,
    isDisabled: false,
    pnl: ONE_BN,
}