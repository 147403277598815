import Grid from '@lyra/ui/components/Grid'
import formatUSD from '@lyra/ui/utils/formatUSD'
import React from 'react'

import { Entry,Market, StarknetOptionQuote } from '@/app/types'

import LabelItem from '../LabelItem'

type Props = {
  option: StarknetOptionQuote
  market: Market
  bids: Entry[] | null
  asks: Entry[] | null
}

const OptionStatsGrid = ({  bids, asks, market, option }: Props) => {
  // const openInterest = option.longOpenInterest.add(option.shortOpenInterest).mul(option.spotPrice).div(UNIT)

  const bid = bids && bids[0] ? (option.optionType==='PUT'? formatUSD(Number(bids[0].price)) : `${Number(bids[0].price)} ${market.baseTokenSymbol}` ): '-'
  const ask = asks && asks[0] ? (option.optionType==='PUT'? formatUSD(Number(asks[0].price)) : `${Number(asks[0].price)} ${market.baseTokenSymbol}` ): '-'
  return (
    <Grid sx={{ gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr 1fr 1fr'], gridColumnGap: [3, 6], gridRowGap: [3, 6] }}>
      <LabelItem
        label="Bid"
        value={bid}
      />
      <LabelItem
        label="Ask"
        value={ask}
      />
      {/* <LabelItem
        label="Bid IV"
        value={bid && bid.iv.gt(0) && bid.iv.lt(MAX_IV) ? formatPercentage(fromBigNumber(bid.iv), true) : '-'}
      />
      <LabelItem
        label="Ask IV"
        value={ask && ask.iv.gt(0) && ask.iv.lt(MAX_IV) ? formatPercentage(fromBigNumber(ask.iv), true) : '-'}
      /> */}
      {/* <LabelItem label="Open Interest" value={formatTruncatedUSD(openInterest)} /> */}
      {/* <LabelItem label="Delta" value={formatNumber(option.delta, { dps: 3 })} />
      <LabelItem label="Vega" value={formatNumber(option.strikePrice, { dps: 3 })} />
      <LabelItem label="Gamma" value={formatNumber(option.strikePrice, { dps: 3 })} />
      <LabelItem label="Theta" value={formatNumber(option.theta, { dps: 3 })} />
      <LabelItem label="Rho" value={formatNumber(option.rho, { dps: 3 })} /> */}
    </Grid>
  )
}

export default OptionStatsGrid
