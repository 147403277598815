import CardSection from '@lyra/ui/components/Card/CardSection'
import CardSeparator from '@lyra/ui/components/Card/CardSeparator'
import Center from '@lyra/ui/components/Center'
import Spinner from '@lyra/ui/components/Spinner'
import Text from '@lyra/ui/components/Text'
import formatUSD from '@lyra/ui/utils/formatUSD'
import { getAddressBook, unwrapDecimals } from '@optiondance/starknet-sdk'
import { newBnWithDecimals } from '@optiondance/starknet-sdk'
import React, { useEffect, useMemo, useState } from 'react'

import AmountUpdateText from '@/app/components/common/AmountUpdateText'
import RowItem from '@/app/components/common/RowItem'
import { TRADE_CARD_MIN_HEIGHT } from '@/app/constants/layout'
import TradeFormSizeInput from '@/app/containers/trade/TradeForm/TradeFormSizeInput'
import { useSnWalletContext } from '@/app/hooks/account/useStarknetWallet'
import withSuspense from '@/app/hooks/data/withSuspense'
import { defaultTrade, Market, Position, StarknetOptionQuote } from '@/app/types'

// import fromBigNumber from '@/app/utils/fromBigNumber'
import SnTradeFormButton from './SnTradeFormButton'

type Props = {
  isBuy: boolean
  isCall: boolean
  option: StarknetOptionQuote
  position?: Position | null
  hideTitle?: boolean
  onTrade?: (market: Market, positionId: number) => void
}

const TradeForm = withSuspense(
  ({ isBuy, isCall, option, position, onTrade, hideTitle }: Props) => {
    const isLong = position ? position.isLong : isBuy
    const { network } = useSnWalletContext()
    const addressbook = getAddressBook(network)
    const [size, setSize] = useState<number | null>(null)
    const trade = defaultTrade
    const pnl = useMemo(() => trade.pnl, [trade])

    useEffect(() => {
      setSize(null)
    }, [option.strikePrice, isCall, isBuy, option])

    const optionPrice = useMemo(() => {
      if (isBuy) {
        return Number(option.asks[0].price)
      } else {
        return Number(option.bids[0].price)
      }
    }, [isBuy, option.asks, option.bids])

    const strikePrice = useMemo(() => {
      return Number(option.strikePrice)
    }, [option.strikePrice])

    const places = useMemo(() => {
      return option.optionType === 'CALL' ? 5 : 3
    }, [option])

    const contractSize = useMemo(() => {
      return size ? size : 0
    }, [size])

    const premium = useMemo(() => {
      return contractSize * optionPrice
    }, [optionPrice, contractSize])

    const collateralAmount = useMemo(() => {
      let erc20 = addressbook.wbtc
      switch (option.quoteCurrency) {
        case 'WBTC':
          erc20 = addressbook.wbtc
          break
        case 'USDC':
          erc20 = addressbook.usdc
          break
        case 'ETH':
          erc20 = addressbook.eth
          break
      }
      let collateralAmountStr = newBnWithDecimals(contractSize * strikePrice, 0).toString()
      if (option.optionType === 'CALL') {
        collateralAmountStr = contractSize.toString()
      }
      return `${collateralAmountStr} ${erc20.symbol}`
    }, [option.quoteCurrency, contractSize, strikePrice])

    const onChangeSize = (size: number) => {
      setSize(size)
    }
    const inputSize = size !== null ? size : null
    return (
      <>
        <CardSection>
          {!hideTitle ? (
            <Text mb={6} variant="cardHeading">
              {isBuy ? 'Buy' : 'Sell'} {'WBTC'} {formatUSD(unwrapDecimals(option.strikePrice, 0).toNumber())}{' '}
              {option.optionType === 'PUT' ? 'PUT' : 'CALL'}
            </Text>
          ) : null}
          {position ? (
            <RowItem
              label="Position"
              value={
                <AmountUpdateText
                  color={position.isLong ? 'primaryText' : 'errorText'}
                  prefix={position.isLong ? 'Long' : 'Short'}
                  prevAmount={position.size}
                  newAmount={trade.newSize}
                />
              }
              mb={6}
            />
          ) : null}
          <RowItem
            label="Contracts"
            value={<TradeFormSizeInput width="60%" trade={defaultTrade} size={inputSize} onChangeSize={onChangeSize} />}
            mb={5}
          />
          <RowItem
            label="Price Per Option"
            value={
              !optionPrice
                ? '-'
                : option.optionType === 'PUT'
                ? `$${optionPrice}`
                : `${optionPrice} ${option.quoteCurrency}`
            }
            valueColor={trade.pricePerOption.isZero() && trade.isDisabled ? 'secondaryText' : 'text'}
          />
        </CardSection>
        <CardSeparator />

        {!isLong ? (
          <>
            <CardSection>
              <RowItem mb={5} label="Collateral" value={collateralAmount} />
            </CardSection>
            <CardSeparator />
          </>
        ) : null}
        <CardSection>
          <RowItem
            mb={5}
            label={isBuy ? 'Max Cost' : 'Min Received'}
            valueColor={trade.premium.gt(0) || !trade.isDisabled ? 'text' : 'secondaryText'}
            value={
              trade.premium.gt(0) || !trade.isDisabled
                ? ` ${option.optionType === 'PUT' ? '$' : ''}${premium.toFixed(places)} ${option.quoteCurrency}`
                : '-'
            }
          />
          {!trade.isOpen ? (
            <RowItem
              mb={5}
              label="Profit / Loss"
              value={pnl.isZero() ? '-' : formatUSD(pnl, { showSign: true })}
              valueColor={pnl.isZero() ? 'secondaryText' : pnl.gt(0) ? 'primaryText' : 'errorText'}
            />
          ) : null}
          <SnTradeFormButton
            isBuy={isBuy}
            isCall={isCall}
            option={option}
            orderPrice={optionPrice}
            orderSize={size}
            collateralAmount={collateralAmount.toString()}
          />
        </CardSection>
        {!position && (
          <>
            <CardSeparator />
            {/* <TradeFormPayoffSection trade={trade} /> */}
          </>
        )}
      </>
    )
  },
  () => (
    <CardSection height={TRADE_CARD_MIN_HEIGHT}>
      <Center width="100%" flexGrow={1}>
        <Spinner />
      </Center>
    </CardSection>
  )
)

export default TradeForm
