import Box from '@lyra/ui/components/Box'
import Flex from '@lyra/ui/components/Flex'
import Table, { TableCellProps, TableColumn, TableData } from '@lyra/ui/components/Table'
import Text from '@lyra/ui/components/Text'
import filterNulls from '@lyra/ui/utils/filterNulls'
import formatUSD from '@lyra/ui/utils/formatUSD'
import { QuoteDisabledReason } from '@lyrafinance/lyra-js'
// import { unwrapDecimals } from '@optiondance/starknet-sdk'
import React, { useMemo, useState } from 'react'

import { Entry, StarknetOption, StarknetOptionQuote } from '@/app/types'

import OptionStatsGrid from '../../common/OptionStatsGrid'
import { TradeBoardTableOrListProps } from '.'
import TradeBoardPriceButton from './TradeBoardPriceButton'

type OptionData = TableData<{
  quote: Entry
  option: StarknetOption
  optionQuote: StarknetOptionQuote
  strike: number
  strikeId: number
  marketAddressOrName: string
  breakEven: number
  toBreakEven: number
  price: number
  disabledReason: QuoteDisabledReason | null
}>

interface OptionProfit {
  breakEven: number
  toBreakEven: number
}

function calcOptionProfit(spotPrice: number, optionPrice: number, strikePrice: number, isCall = true): OptionProfit {
  let breakEven: number
  let toBreakEven: number

  if (isCall) {
    optionPrice = optionPrice * spotPrice
    breakEven = strikePrice + optionPrice
    toBreakEven = breakEven - spotPrice
  } else {
    breakEven = strikePrice - optionPrice
    toBreakEven = breakEven - spotPrice
  }

  return {
    breakEven,
    toBreakEven,
  }
}

const TradeBoardTableDesktop = ({
  market,
  board,
  optionQuoteList,
  isBuy,
  selectedOption,
  onSelectOption,
}: TradeBoardTableOrListProps) => {
  const [expandedStrikes, setExpandedStrikes] = useState<Record<string, boolean>>({})
  const spotPrice = market.baseSpotPrice
  const rows: OptionData[] = useMemo(() => {
    const rows = filterNulls(
      optionQuoteList.map(e => {
        const bids = e.bids
        const asks = e.asks
        const option = e
        const quote = isBuy ? asks[0] : bids[0]
        if (!quote) {
          return null
        }
        const isExpanded = !!expandedStrikes[e.instrumentName]
        const strikeId = e.instrumentName
        const optionPrice = Number(quote.price)
        const strikePrice = e.strikePrice
        const optionProfit = calcOptionProfit(spotPrice, optionPrice, strikePrice, option.optionType === 'CALL')
        const breakEven = optionProfit.breakEven
        const toBreakEven = optionProfit.toBreakEven

        return {
          id: strikeId.toString(),
          quote,
          optionQuote: e,
          strikeId,
          option: option,
          marketAddressOrName: 'quote.market().address',
          strike: strikePrice,
          price: optionPrice,
          breakEven: breakEven,
          toBreakEven: toBreakEven,
          disabledReason: null,
          isExpanded,
          onToggleExpand: (isExpanded: boolean) => {
            setExpandedStrikes(expandedStrikes => ({
              ...expandedStrikes,
              [e.instrumentName]: isExpanded,
            }))
            // logEvent(isExpanded ? LogEvent.BoardStrikeExpand : LogEvent.BoardStrikeCollapse, getLogData(quote))
          },
          isExpandedContentClickable: true,
          expanded: (
            <Box pb={4} px={3}>
              <Text variant="bodyMedium" mb={6}>
                Orderbook
              </Text>
              <OptionStatsGrid market={market} option={e} bids={bids} asks={asks} />
            </Box>
          ),
        }
      })
    )
    return rows
  }, [optionQuoteList, isBuy, expandedStrikes])

  const columns = useMemo<TableColumn<OptionData>[]>(
    () => [
      {
        accessor: 'strike',
        Header: 'Strike',
        disableSortBy: true,
        Cell: (props: TableCellProps<OptionData>) => (
          <Text variant="bodyMedium">{props.cell.value > 0 ? formatUSD(props.cell.value) : '-'}</Text>
        ),
      },
      {
        accessor: 'breakEven',
        Header: 'Break Even',
        disableSortBy: true,
        Cell: (props: TableCellProps<OptionData>) => (
          <Text>{props.cell.value > 0 ? formatUSD(props.cell.value) : '-'}</Text>
        ),
      },
      {
        accessor: 'toBreakEven',
        Header: 'To Break Even',
        disableSortBy: true,
        Cell: (props: TableCellProps<OptionData>) => (
          <Text>{(props.cell.value > 0 ? '+' : '') + formatUSD(props.cell.value)}</Text>
        ),
      },
      {
        accessor: 'price',
        Header: 'Price',
        disableSortBy: true,
        width: 120,
        Cell: (props: TableCellProps<OptionData>) => {
          const { quote, strikeId, id } = props.row.original
          const isSelected = selectedOption?.instrumentName === id
          return (
            <TradeBoardPriceButton
              isBuy={isBuy}
              option={props.row.original.option}
              quote={quote}
              isSelected={isSelected}
              onSelected={isSelected => onSelectOption(props.row.original.optionQuote, isSelected)}
            />
          )
        },
      },
    ],
    [selectedOption?.instrumentName, isBuy, onSelectOption]
  )

  const firstOption = rows[0]
  const spotPriceMarker = useMemo(() => {
    let spotPriceRowIdx = rows.reduce(
      (markerIdx, row) => (row.option.strikePrice && spotPrice < row.option.strikePrice ? markerIdx : markerIdx + 1),
      0
    )

    if (firstOption && firstOption.option.optionType === 'CALL') {
      spotPriceRowIdx = rows.reduce(
        (markerIdx, row) => (row.option.strikePrice && spotPrice > row.option.strikePrice ? markerIdx : markerIdx + 1),
        0
      )
    }
    return {
      rowIdx: spotPriceRowIdx,
      content: (
        <Flex sx={{ position: 'relative' }} alignItems="center" width="100%">
          <Box sx={{ position: 'absolute', right: 0, left: 0, top: '18px', height: '4px', bg: 'cardHoverBg' }} />
          <Flex
            mx={[2, 3]}
            px={[1, 3]}
            my={1}
            py={1}
            bg="cardHoverBg"
            sx={{ borderRadius: 'list', position: 'relative', zIndex: 1 }}
          >
            <Text variant="small">
              {market.baseTokenSymbol} Price:{' '}
              <Text as="span" color="primaryText">
                {formatUSD(spotPrice)}
              </Text>
            </Text>
          </Flex>
        </Flex>
      ),
    }
  }, [rows, spotPrice])

  if (rows.length === 0) {
    return null
  }

  return <Table data={rows} columns={columns} tableRowMarker={spotPriceMarker} />
}

export default TradeBoardTableDesktop
