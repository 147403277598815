export function generateOrderSalt(): string {

    let result = '';

    for (let i = 0; i < 16; i++) {
        result += Math.floor(Math.random() * 10);
    }

    return result;

}
