import { LogEvent } from '../constants/logEvents'
import { PageId } from '../constants/pages'
import getPagePath from '../utils/getPagePath'
import isMainnet from '../utils/isMainnet'

type Tab = {
  path: string
  rootPageId: PageId
  name: string
  logEvent: LogEvent
}

const TABS: Tab[] = [
  {
    path: getPagePath({ page: PageId.TradeIndex }),
    rootPageId: PageId.Trade,
    name: 'Trade',
    logEvent: LogEvent.NavTradeTabClick,
  }
]

if (!isMainnet()) {
  TABS.push({
    path: getPagePath({ page: PageId.Faucet }),
    rootPageId: PageId.Faucet,
    name: 'Faucet',
    logEvent: LogEvent.NavFaucetTabClick,
  })
}


export default TABS
