import { IconType } from '@lyra/ui/components/Icon'
import Spinner from '@lyra/ui/components/Spinner'
import { createToast, updateToast } from '@lyra/ui/components/Toast'
import { Controller, ERC20, Exchange, Message, MessageOrderType, PragmaPricerContract } from '@optiondance/starknet-sdk'
import { mintTestToken } from '@optiondance/starknet-sdk'
import BigNumber from 'bignumber.js'
import React from 'react'

import { toSnSDKNetwork } from '@/app/constants/networks'
import { getSnExplorerTxUrl } from '@/app/constants/networks'

import useStarknetWallet from '../account/useStarknetWallet'
export function useSnContract() {
  const { account, network, wallet } = useStarknetWallet()
  const snNetwork = toSnSDKNetwork(network)

  const ToastSpinner = () => <Spinner size={20} />
  const getToastId = () => {
    return createToast({
      variant: 'info',
      icon: <ToastSpinner />,
      description: 'Confirm your transaction',
      autoClose: false,
    })
  }

  const events = (toastId: string) => {
    return {
      onCompleted: (txhash: string) => {
        console.log('tx complete', txhash)
        updateToast(toastId, {
          variant: 'success',
          description: 'Your transaction was successful.',
          href: getSnExplorerTxUrl(network, txhash),
          hrefLabel: 'View on starkscan',
          target: '_blank',
          autoClose: 5000,
          icon: IconType.Check,
        })
      },
      onPending: (txhash: string) => {
        console.log('tx onPending', txhash)
        updateToast(toastId, {
          variant: 'info',
          icon: <ToastSpinner />,
          description: 'Your transaction is pending.',
          href: getSnExplorerTxUrl(network, txhash),
          hrefLabel: 'View on starkscan',
          target: '_blank',
          autoClose: false,
        })
      },
      onError: (txhash: string) => {
        console.log('tx onError', txhash)
        updateToast(toastId, {
          variant: 'error',
          icon: IconType.AlertTriangle,
          description: 'Your transaction is failed.',
          href: getSnExplorerTxUrl(network, txhash),
          hrefLabel: 'View on starkscan',
          target: '_blank',
          autoClose: 3000,
        })
      },
    }
  }

  const createOption = async (
    underlyingAsset: string,
    quoteAsset: string,
    strikePrice: number,
    expiry: number,
    optionType: number
  ) => {
    const controller = new Controller(snNetwork)
    await controller.createInstrument(
      account,
      underlyingAsset,
      quoteAsset,
      strikePrice,
      expiry,
      optionType,
      events(getToastId())
    )
  }

  const mintOption = async (otoken: string, amount: number) => {
    const controller = new Controller(snNetwork)

    await controller.mintOption(account, otoken, amount, events(getToastId()))
  }

  const settle = async (writerToken: string, amount: number) => {
    const controller = new Controller(snNetwork)
    await controller.settle(account, writerToken, amount, events(getToastId()))
  }

  const exercise = async (otoken: string, amount: number) => {
    const controller = new Controller(snNetwork)
    await controller.exercise(account, otoken, amount, events(getToastId()))
  }

  const closePosition = async (otoken: string, amount: number) => {
    const controller = new Controller(snNetwork)
    await controller.closePosition(account, otoken, amount, events(getToastId()))
  }

  const getOptionByName = async (name: string) => {
    const controller = new Controller(snNetwork)
    try {
      return controller.getInstrument(account, name)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const signOrderMessage = async (
    instrumentName: string,
    baseAsset: ERC20,
    side: MessageOrderType,
    price: number,
    baseAmount: number,
    expiration: string,
    salt: string
  ): Promise<[Message, string]> => {
    const exchange = new Exchange(snNetwork)
    return await exchange.signOrderMessage(
      account,
      instrumentName,
      baseAsset,
      side,
      price,
      baseAmount,
      expiration,
      salt
    )
  }

  const getOracleSpotMedian = async (asset: string) => {
    const oracle = new PragmaPricerContract(snNetwork)
    let pariId = 0
    if (asset === 'WBTC') {
      pariId = 18669995996566340
    }
    if (asset === 'ETH') {
      pariId = 19514442401534788
    }
    const res = await oracle.getSpotMedian(account, pariId)
    return res
  }

  const mintToken = async (tokenAddress: string, to: string, mintAmount: BigNumber) => {
    await mintTestToken(account, wallet.provider, tokenAddress, to, mintAmount.toString(10), events(getToastId()))
  }

  const multicall = async (callDataArray: any) => {
    const controller = new Controller(snNetwork)
    await controller.call(account, callDataArray, events(getToastId()))
  }
  return {
    mintOption,
    signOrderMessage,
    settle,
    exercise,
    closePosition,
    getOracleSpotMedian,
    createOption,
    getOptionByName,
    mintToken,
    multicall
  }
}
