import Button from '@lyra/ui/components/Button'
import Flex from '@lyra/ui/components/Flex'
import ButtonShimmer from '@lyra/ui/components/Shimmer/ButtonShimmer'
import useIsDarkMode from '@lyra/ui/hooks/useIsDarkMode'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LayoutProps, MarginProps } from 'styled-system'

// import Avatar from '@/app/components/common/Avatar'
import NetworkDropdownButton from '@/app/components/common/NetworkDropdownButton/NetworkDropdownButton'
import { LOCAL_STORAGE_DEFAULT_NETWORK_KEY } from '@/app/constants/localStorage'
import { StarknetNetwork } from '@/app/constants/networks'
import { PageId } from '@/app/constants/pages'
// import useENS from '@/app/hooks/account/useENS'
import useStarknetWallet from '@/app/hooks/account/useStarknetWallet'
// import useNetwork from '@/app/hooks/account/useNetwork'
// import useWallet from '@/app/hooks/account/useWallet'
import withSuspense from '@/app/hooks/data/withSuspense'
import useLocalStorage from '@/app/hooks/local_storage/useLocalStorage'
import formatTruncatedAddress from '@/app/utils/formatTruncatedAddress'
import { getNavPageFromPath } from '@/app/utils/getNavPageFromPath'
import getPagePath from '@/app/utils/getPagePath'

import ConnectWalletButton from '../ConnectWalletButton'
import AccountModal from './AccountModal'

type Props = Omit<LayoutProps, 'size'> & MarginProps

const AccountButton = withSuspense(
  ({ ...styleProps }: Props) => {
    const [isDarkMode] = useIsDarkMode()
    // const { chainId, account, isLoading, isConnected, isOverride, removeSeeAddress, switchNetwork } = useWallet()
    const { account, isLoading, isConnected, network, updateNetwork } = useStarknetWallet()

    // const network = useNetwork()
    // const { chain } = useNetwork()
    // const network = useMemo(() => {
    // }, [chain])
    const [_, setDefaultNetwork] = useLocalStorage(LOCAL_STORAGE_DEFAULT_NETWORK_KEY)
    const [isShowAccountModal, setIsShowAccountModal] = useState(false)

    const getButtonLabel = useCallback(() => {
      const accountStr = account ? formatTruncatedAddress(account.address) : ''
      return accountStr
    }, [account])

    const { pathname } = useLocation()
    const navigate = useNavigate()

    const rootPage = getNavPageFromPath(pathname)

    const handleSelectNetwork = useCallback(
      async (newNetwork: StarknetNetwork) => {
        console.log(newNetwork)
        if (true) {
          console.log('switch network')
        }
        updateNetwork(newNetwork)
        const networkRoute = getPagePath({
          page: PageId.Trade,
          network: newNetwork,
          marketAddressOrName: "WBTC",
        })
        navigate(networkRoute)
        window.location.href = networkRoute
      },
      [navigate, updateNetwork]
    )

    return (
      <Flex {...styleProps}>
        <NetworkDropdownButton selectedNetwork={network} onSelectNetwork={handleSelectNetwork} mr={2} />
        {account ? (
          <ConnectButton.Custom>
            {({ openAccountModal, account: uiAccount }) => {
              const variant = false ? 'warning' : isDarkMode ? 'static' : 'white'
              const onClick = async () => {
                console.log('click');
                if(isConnected) {
                  setIsShowAccountModal(true)
                }
              }
              if (uiAccount) {
                Object.defineProperty(uiAccount, 'address', {
                  value: account.address,
                  writable: true,
                })
                // uiAccount.address = account.address
              }

              return (
                <Button
                  rightIconSpacing={1}
                  // rightIcon={<Avatar size={18} ensImage={ens?.avatarURL} address={account} />}
                  isLoading={isLoading}
                  variant={variant}
                  label={getButtonLabel()}
                  onClick={onClick}
                />
              )
            }}
          </ConnectButton.Custom>
        ) : (
          <ConnectWalletButton network={StarknetNetwork.SNGOERLI} {...styleProps} />
        )}

        <AccountModal
          isOpen={isShowAccountModal}
          onClose={()=> {
            setIsShowAccountModal(false)
          }
        }
        />
      </Flex>
    )
  },
  ({ ...styleProps }: Props) => (
    <Flex {...styleProps}>
      <ButtonShimmer width={[62, 136.37]} mr={2} />
      <ButtonShimmer width={138.84} />
    </Flex>
  )
)

export default AccountButton
