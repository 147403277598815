
import getAssetSrc from '@/app/utils/getAssetSrc'

export default function getMarketLogoURI(baseTokenSymbol: string): string {
  switch (baseTokenSymbol.toLowerCase()) {
    case 'seth':
    case 'eth':
    case 'weth':
      return getAssetSrc('/images/ethereum-logo.png')
    case 'btc':
    case 'sbtc':
    case 'wbtc':
      return getAssetSrc('/images/bitcoin-logo.png')
    case 'sol':
    case 'ssol':
      return getAssetSrc('/images/solana-logo.png')
    case 'op':
      return getAssetSrc('/images/op-logo.svg')
    case 'lyarb':
    case 'arb':
      return getAssetSrc('/images/arbitrum-logo.svg')
    case 'testnet':
      return getAssetSrc('/images/wallet_testnet.svg')
    case 'starknet':
      return getAssetSrc('/images/wallet_starknet.svg')  
    default:
      return ''
  }
}
