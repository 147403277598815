import React from 'react'

import { Board, Market, OptionQuoteList, StarknetOptionQuote } from '@/app/types'

import TradeBoardTableDesktop from './TradeBoardTableDesktop'

export type TradeBoardTableOrListProps = {
  market: Market
  board: Board
  // quotes: Entry[]
  optionQuoteList: OptionQuoteList
  isBuy: boolean
  selectedOption: StarknetOptionQuote | null
  onSelectOption: (option: StarknetOptionQuote, isSelected: boolean) => void
}

const TradeBoardTableOrList = (props: TradeBoardTableOrListProps): JSX.Element => {
  return <TradeBoardTableDesktop {...props} />
}

export default TradeBoardTableOrList
