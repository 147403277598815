import { IconType } from '@lyra/ui/components/Icon'
import { createToast } from '@lyra/ui/components/Toast'

export function ToastError(messsage: string) {
  createToast({
    variant: 'error',
    icon: IconType.AlertTriangle,
    description: messsage,
    autoClose: 5000,
  })
}


export function ToastSuccess(messsage: string) {
  createToast({
    variant: "success",
    icon: IconType.AlertCircle,
    description: messsage,
    autoClose: 5000,
  })
}
