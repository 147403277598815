import DropdownButton from '@lyra/ui/components/Button/DropdownButton'
import DropdownButtonListItem from '@lyra/ui/components/Button/DropdownButtonListItem'
import { DropdownIconButtonElement } from '@lyra/ui/components/Button/DropdownIconButton'
import React, { useCallback, useState } from 'react'

import MarketImage from '@/app/components/common/MarketImage'
import MarketLabel from '@/app/components/common/MarketLabel'
import { PageId } from '@/app/constants/pages'
import useStarknetWallet from '@/app/hooks/account/useStarknetWallet'
import { Market } from '@/app/types'
import getMarketDisplayName from '@/app/utils/getMarketDisplayName'
import getPagePath from '@/app/utils/getPagePath'


type Props = {
  markets: Market[]
  selectedMarket: Market
  onChangeMarket: (market: Market) => void
}

const TradeMarketDropdown = ({
  markets,
  selectedMarket,
  onChangeMarket,
  ...styleProps
}: Props): DropdownIconButtonElement => {
  const {network} = useStarknetWallet()
  const [isOpen, setIsOpen] = useState(false)
  const onClose = useCallback(() => setIsOpen(false), [])
  return (
    <DropdownButton
      {...styleProps}
      isOpen={isOpen}
      onClose={onClose}
      onClick={() => setIsOpen(true)}
      textVariant="heading"
      isTransparent
      label={getMarketDisplayName(selectedMarket.baseTokenSymbol)}
      leftIcon={<MarketImage market={selectedMarket} />}
      mobileTitle="Select Market"
    >
      {markets.map(market => (
        <DropdownButtonListItem
          onClick={() => {
            onChangeMarket(market)
            onClose()
          }}
          key={market.baseTokenSymbol}
          isSelected={market.baseTokenSymbol === selectedMarket.baseTokenSymbol}
          label={<MarketLabel market={market} />}
          href={getPagePath({ page: PageId.Trade, network: network, marketAddressOrName: market.baseTokenSymbol })}
          // rightContent={<TradeMarketDropdownSpotPrice market={market} />}
        />
      ))}
    </DropdownButton>
  )
}

export default TradeMarketDropdown
