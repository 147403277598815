
export default function getMarketDisplayName(baseTokenSymbol: string): string {
  switch (baseTokenSymbol.toLowerCase()) {
    case 'eth':
    case 'seth':
    case 'weth':
      return 'Ethereum'
    case 'btc':
    case 'sbtc':
    case 'wbtc':
      return 'Bitcoin'
    case 'sol':
    case 'ssol':
      return 'Solana'
    case 'op':
      return 'Optimism'
    case 'arb':
    case 'lyarb':
      return 'Arbitrum'
    default:
      return ''
  }
}
