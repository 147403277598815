// import CardSeparator from '@lyra/ui/components/Card/CardSeparator'
import Button from '@lyra/ui/components/Button'
import Flex from '@lyra/ui/components/Flex'
// import Icon, { IconType } from '@lyra/ui/components/Icon'
import Modal from '@lyra/ui/components/Modal'
import ModalSection from '@lyra/ui/components/Modal/ModalSection'
import Text from '@lyra/ui/components/Text'
import copy from 'copy-to-clipboard'
import React, { useMemo } from 'react'

import useStarknetWallet from '@/app/hooks/account/useStarknetWallet'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const AccountModal = ({ isOpen, onClose }: Props) => {
  const { account, disconnect } = useStarknetWallet()

  function toWalletAddress(str: string) {
    if (str.length <= 11) {
      return str
    } else {
      const firstPart = str.substring(0, 6)
      const lastPart = str.substring(str.length - 4)
      return firstPart + '...' + lastPart
    }
  }

  const walletAddress = useMemo(() => {
    if (!account) return
    return toWalletAddress(account.address)
  }, [account])

  return (
    <Modal title="Account" isOpen={isOpen} onClose={onClose}>
      <ModalSection>
        <Flex mb={4} alignItems="center" justifyContent={'center'}>
          <Text variant="bodyMedium">{walletAddress}</Text>
        </Flex>
        <Flex mb={2} alignItems="center" justifyContent={'center'}>
          <Button
            mr={3}
            label="Copy Address"
            size="medium"
            variant="primary"
            onClick={() => {
              copy(account.address)
            }}
          />

          <Button
            label="Disconnect"
            size="medium"
            variant="primary"
            onClick={() => {
              disconnect()
              onClose()
            }}
          />
        </Flex>
      </ModalSection>
    </Modal>
  )
}

export default AccountModal
