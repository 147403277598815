import emptyFunction from '@lyra/ui/utils/emptyFunction'
import React, { useCallback, useEffect, useState } from 'react'

import Button from '../Button'
import Flex from '../Flex'
import Input, { InputProps } from '.'

export type Props = {
  value: number
  placeholder?: number | string
  onChange: (value: number) => void
  min: number
  max: number
  customMaxMessage?: string
  customMinMessage?: string
  showMaxButton?: boolean
  onEmpty?: () => void
} & Omit<InputProps, 'placeholder' | 'value' | 'onChange' | 'type' | 'error' | 'onError'>

export default function NumberInput({
  value,
  onChange,
  onBlur = emptyFunction,
  onFocus = emptyFunction,
  customMaxMessage,
  customMinMessage,
  max = 99999,
  min = 0.1,
  autoFocus,
  onEmpty,
  showMaxButton,
  placeholder = '0.0',
  ...inputProps
}: Props): JSX.Element {
  const [error, setError] = useState<string | false>(false)
  const [rawValue, setRawValue] = useState('')
  const checkError = useCallback(
    (value: number) => {
      let error: string | false = false
      if (value > max) {
        error = customMaxMessage ?? 'Input is too large'
      } else if (value < min) {
        error = customMinMessage ?? 'Input is too small'
      } else {
        setError(false)
      }
      setError(false)
      return error
    },
    [setError, customMaxMessage, customMinMessage, max, min]
  )

  useEffect(() => {
    // Check for any dynamic value updates
    if (value && value > 0) {
      checkError(value)
    }
    if(value === null) {
      setRawValue('')
    }
  }, [value, checkError, rawValue])

  function fixPoint(numStr: string): string {
    return numStr.replace(/^(\d+\.?\d{0,1}).*$/, '$1')
  }

  return (
    <Input
      {...inputProps}
      value={rawValue}
      placeholder={'0.0'}
      onChange={evt => {
        // type="number" ensures inputVal is a valid number or ''
        const inputVal = evt.target.value ?? ''
        const val = fixPoint(inputVal)
        setRawValue(val)
        const bn = Number(val)
        if (inputVal) {
          if (bn !== null) {
            onChange(bn)
            checkError(bn)
            return
          }
        } else {
          if (onEmpty) {
            onEmpty()
          } else {
            onChange(bn)
          }
        }
      }}
      autoFocus={autoFocus}
      type="number"
      onFocus={e => {
        onFocus(e)
        checkError(value)
      }}
      onBlur={e => {
        onBlur(e)
        checkError(value)
      }}
      error={error}
      rightContent={
        <Flex alignItems="center">
          {inputProps.rightContent}
          {showMaxButton ? (
            <Button
              ml={2}
              size="sm"
              isTransparent
              variant="light"
              onClick={e => {
                e.preventDefault()
                if (onChange) {
                  onChange(max ?? 0)
                }
              }}
              label="Max"
            />
          ) : null}
        </Flex>
      }
    />
  )
}
