import Box from '@lyra/ui/components/Box'
import DropdownButton from '@lyra/ui/components/Button/DropdownButton'
import DropdownButtonListItem from '@lyra/ui/components/Button/DropdownButtonListItem'
import ToggleButton from '@lyra/ui/components/Button/ToggleButton'
import ToggleButtonItem from '@lyra/ui/components/Button/ToggleButtonItem'
import CardSection from '@lyra/ui/components/Card/CardSection'
import CardSeparator from '@lyra/ui/components/Card/CardSeparator'
import Flex from '@lyra/ui/components/Flex'
import { IconType } from '@lyra/ui/components/Icon'
import Text from '@lyra/ui/components/Text'
import useIsMobile from '@lyra/ui/hooks/useIsMobile'
import formatDate from '@lyra/ui/utils/formatDate'
import formatDateTime from '@lyra/ui/utils/formatDateTime'
import React, { useCallback, useEffect, useState } from 'react'

import { API } from '@/app/api'
// import { Market } from '@lyrafinance/lyra-js'
import { Market, OptionQuoteList, StarknetOptionQuote } from '@/app/types'
import { Board } from '@/app/types'

import TradeSimpleBoardTable from './TradeSimpleBoardTable'

type Props = {
  market: Market
  optionQuoteList: OptionQuoteList
  onSetOptionQuoteList: (list: OptionQuoteList) => void
  isCall: boolean
  onToggleCall: (isCall: boolean) => void
  isBuy: boolean
  onToggleBuy: (isBuy: boolean) => void
  selectedOption: StarknetOptionQuote | null
  onSelectOption: (option: StarknetOptionQuote, isSelected: boolean) => void
  selectedBoard: Board
  onSelectBoard: (board: Board) => void
}

const TradeSimpleBoardCard = ({
  market,
  isCall,
  isBuy,
  selectedOption,
  selectedBoard,
  optionQuoteList,
  onSetOptionQuoteList,
  onSelectOption,
  onSelectBoard,
  onToggleBuy,
  onToggleCall,
}: Props) => {
  const [boards, setBoards] = useState<any[]>([])
  const isMobile = useIsMobile()
  const [isOpen, setIsOpen] = useState(false)
  const onClose = useCallback(() => setIsOpen(false), [])

  useEffect(() => {
    const fetchBoards = async () => {
      const optionType = isCall ? 'CALL' : 'PUT'
      const side = isBuy ? 'ASK' : 'BID'
      const resp = await API().listExpiryDates({
        baseCurrency: 'BTC',
        quoteCurrency: 'USDC',
        optionType: optionType,
        side: side,
      })
      setBoards(resp.data)
      if (resp.data && resp.data.length > 0) {
        let isReSelected = false
        for (const board of resp.data) {
          if (board.expiryTimestamp === selectedBoard.expiryTimestamp) {
            onSelectBoard(board)
            isReSelected = true
          }
        }
        if (!isReSelected) {
          onSelectBoard(resp.data[0])
        }
      } else {
        onSetOptionQuoteList([])
      }
    }
    fetchBoards()
  }, [isBuy, isCall])

  return (
    <>
      <CardSection>
        <Flex>
          <ToggleButton mr={[2, 4]}>
            <ToggleButtonItem
              id={1}
              label="Buy"
              isSelected={isBuy}
              onSelect={() => {
                onToggleBuy(true)
                // logEvent(LogEvent.BoardIsBuyToggle, {
                //   marketName: market.name,
                //   marketAddress: market.address,
                //   boardId: selectedBoard?.id,
                //   expiryTimestamp: selectedBoard?.expiryTimestamp,
                //   isCall,
                //   isBuy: true,
                // })
              }}
            />
            <ToggleButtonItem
              id={0}
              label="Sell"
              isSelected={!isBuy}
              onSelect={() => {
                onToggleBuy(false)
                // logEvent(LogEvent.BoardIsBuyToggle, {
                //   marketName: market.name,
                //   marketAddress: market.address,
                //   boardId: selectedBoard?.id,
                //   expiryTimestamp: selectedBoard?.expiryTimestamp,
                //   isCall,
                //   isBuy: false,
                // })
              }}
            />
          </ToggleButton>
          <ToggleButton mr={[2, 4]}>
            <ToggleButtonItem
              id={1}
              label="Call"
              isSelected={isCall}
              onSelect={() => {
                onToggleCall(true)
                // logEvent(LogEvent.BoardIsCallToggle, {
                //   marketName: market.name,
                //   marketAddress: market.address,
                //   boardId: selectedBoard?.id,
                //   expiryTimestamp: selectedBoard?.expiryTimestamp,
                //   isBuy,
                //   isCall: true,
                // })
              }}
            />
            <ToggleButtonItem
              id={0}
              label="Put"
              isSelected={!isCall}
              onSelect={() => {
                onToggleCall(false)
                // logEvent(LogEvent.BoardIsCallToggle, {
                //   marketName: market.name,
                //   marketAddress: market.address,
                //   boardId: selectedBoard?.id,
                //   expiryTimestamp: selectedBoard?.expiryTimestamp,
                //   isBuy,
                //   isCall: false,
                // })
              }}
            />
          </ToggleButton>
          <DropdownButton
            ml="auto"
            minWidth={isMobile ? 150 : 350}
            label={
              boards.length === 0
                ? 'No Expiries'
                : selectedBoard
                ? `Expires ${
                    isMobile
                      ? formatDate(selectedBoard.expiryTimestamp, true)
                      : formatDateTime(selectedBoard.expiryTimestamp, { hideYear: true })
                  }`
                : 'Expiry does not exist'
            }
            mobileTitle="Select Expiry"
            isOpen={isOpen}
            onClose={onClose}
            onClick={() => setIsOpen(true)}
            isDisabled={!boards.length}
          >
            {boards.map(board => (
              <DropdownButtonListItem
                key={board.id}
                isSelected={board.id === selectedBoard?.id}
                onClick={() => {
                  onSelectBoard(board)
                  // logEvent(LogEvent.BoardExpirySelect, {
                  //   marketName: market.name,
                  //   marketAddress: market.address,
                  //   boardId: board.id,
                  //   expiryTimestamp: board.expiryTimestamp,
                  //   isBuy,
                  //   isCall,
                  // })
                  onClose()
                }}
                label={formatDateTime(board.expiryTimestamp, { hideYear: true })}
                rightContent={board.id === selectedBoard?.id ? IconType.Check : null}
              />
            ))}
          </DropdownButton>
        </Flex>
      </CardSection>
      {isMobile ? <CardSeparator /> : null}
      {selectedBoard && boards.length > 0 ? (
        <TradeSimpleBoardTable
          market={market}
          board={selectedBoard}
          optionQuoteList={optionQuoteList}
          isCall={isCall}
          isBuy={isBuy}
          onSelectOption={onSelectOption}
          selectedOption={selectedOption}
        />
      ) : (
        <Box sx={{ height: '160px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text variant="small" color="secondaryText">
            Sold Out
          </Text>
        </Box>
      )}
    </>
  )
}

export default TradeSimpleBoardCard
