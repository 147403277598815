export function timestampToDateString(timestamp: number): string {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const dateString = `${year}-${month}-${day}`;
  return dateString;
}



export function firstCharUpper(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}