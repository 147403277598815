import Button, { ButtonElement } from '@lyra/ui/components/Button'
import { IconType } from '@lyra/ui/components/Icon'
import { MarginProps } from '@lyra/ui/types'
import formatUSD from '@lyra/ui/utils/formatUSD'
import { QuoteDisabledReason } from '@lyrafinance/lyra-js'
import React from 'react'

import { Entry, StarknetOption } from '@/app/types'
import emptyFunction from '@/app/utils/emptyFunction'

type Props = {
  quote: Entry
  option: StarknetOption
  isBuy: boolean
  isSelected: boolean
  onSelected: (isSelected: boolean) => void
} & MarginProps

const getDisabledMessage = (disabledReason: QuoteDisabledReason): string => {
  switch (disabledReason) {
    case QuoteDisabledReason.EmptySize:
    case QuoteDisabledReason.Expired:
    case QuoteDisabledReason.TradingCutoff:
    case QuoteDisabledReason.InsufficientLiquidity:
    case QuoteDisabledReason.DeltaOutOfRange:
    case QuoteDisabledReason.UnableToHedgeDelta:
    case QuoteDisabledReason.PriceVarianceTooHigh:
      return 'Disabled'
    case QuoteDisabledReason.VolTooHigh:
    case QuoteDisabledReason.VolTooLow:
    case QuoteDisabledReason.IVTooHigh:
      return 'Sell Only'
    case QuoteDisabledReason.IVTooLow:
    case QuoteDisabledReason.SkewTooHigh:
    case QuoteDisabledReason.SkewTooLow:
      return 'Buy Only'
  }
}

export default function TradeBoardPriceButton({
  quote,
  isSelected,
  isBuy,
  option,
  onSelected = emptyFunction,
  ...styleProps
}: Props): ButtonElement {
  // const isDisabled = disabledReason ? getIsQuoteHidden(disabledReason) : false
  const isDisabled = false
  const disabledReason = ''
  let priceText = formatUSD(Number(quote.price))
  if (option.optionType === 'CALL') {
    priceText = `${quote.price} ${option.baseCurrency}`
  }
  return (
    <Button
      variant={isBuy ? 'primary' : 'error'}
      width={150}
      showRightIconSeparator={!isDisabled}
      isOutline={!isSelected}
      isDisabled={isDisabled}
      label={isDisabled && disabledReason ? getDisabledMessage(disabledReason) : priceText}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopPropagation()
        onSelected(!isSelected)
      }}
      rightIcon={!isDisabled ? (!isSelected ? IconType.Plus : IconType.Check) : null}
      {...styleProps}
    />
  )
}
