import Center from '@lyra/ui/components/Center'
import LineChart from '@lyra/ui/components/LineChart'
import Spinner from '@lyra/ui/components/Spinner'
import { LayoutProps, MarginProps } from '@lyra/ui/types'
import React from 'react'
import useSWR from 'swr'

import { API } from '@/app/api'
import { ChartInterval } from '@/app/constants/chart'
import withSuspense from '@/app/hooks/data/withSuspense'
import { Market } from '@/app/types'
import emptyFunction from '@/app/utils/emptyFunction'
import formatTimestampTooltip from '@/app/utils/formatTimestampTooltip'

type Props = {
  market: Market
  interval: ChartInterval
  hoverSpotPrice: number | null
  onHover: (spotPrice: number | null) => void
} & MarginProps &
  LayoutProps

interface fetchParams {
  args: {
    symbol: string
    timerange: string
  }
}

const fetchSpotPrice = ({ args }: fetchParams) => {
  const symbol = args.symbol
  const timerange = args.timerange
  return API()
    .listSpotPrices(symbol, timerange)
    .then(res => res.data)
}

export const useSnSpotPrices = (market: Market, interval: ChartInterval) => {
  let timerange = ''
  let symbol = 'BTC'
  if (market.baseTokenSymbol === 'ETH') {
    symbol = 'ETH'
  }
  switch (interval) {
    case 'OneDay':
      timerange = '1D'
      break
    case 'OneWeek':
      timerange = '1W'
      break
    case 'OneMonth':
      timerange = '1M'
      break
    case 'ThreeMonths':
      timerange = '3M'
      break
  }

  const { data: history } = useSWR(
    {
      url: '/market/spot-prices',
      args: {
        symbol: symbol,
        timerange: timerange,
      },
    },
    fetchSpotPrice
  )
  return {
    history,
  }
}

const SpotPriceLineChart = withSuspense(
  ({ market, interval, onHover = emptyFunction, hoverSpotPrice, ...styleProps }: Props) => {
    const { history } = useSnSpotPrices(market, interval)
    const defaultSpotPrice = market ? market.baseSpotPrice : null
    const spotPrice = hoverSpotPrice ?? defaultSpotPrice
    const prevSpotPrice = history.length > 0 ? history[0].price : null
    const pctChange = spotPrice && prevSpotPrice ? (spotPrice - prevSpotPrice) / prevSpotPrice : 0
    return (
      <LineChart
        {...styleProps}
        type="linear"
        data={history}
        dataKeys={[{ key: 'price', label: 'price' }]}
        onHover={pt => onHover(pt?.price ?? null)}
        lineColor={'primary'}
        renderTooltip={({ x }) => formatTimestampTooltip(x, interval)}
      />
    )
  },
  ({ market, interval, hoverSpotPrice, onHover, ...styleProps }: Props) => (
    <Center height="100%" {...styleProps}>
      <Spinner />
    </Center>
  )
)

export default React.memo(SpotPriceLineChart)